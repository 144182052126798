import { AcpService } from "./ACPService";

export const AcpCommonService = {
  removeEmptyArraysWithNoData(obj) {
    const result = {};

    for (let key in obj) {
      if (!(Array.isArray(obj[key]) && obj[key].length === 0)) {
        result[key] = obj[key];
      }
    }

    return result;
  },
  isFieldBlankOrSingle(selectedField, selectedColumnName) {
    // Check if selectedField is null, undefined, an empty string, or an empty object
    if (!selectedField || (selectedField && Object.keys(selectedField).length === 0)) {
      return true;
    }

    // Check if the object has exactly one key and if that key matches the selectedColumnName
    const keys = Object.keys(selectedField);
    if (keys && keys.length === 1 && keys[0] === selectedColumnName) {
      const value = selectedField[selectedColumnName];

      // Check if the value is an array (regardless of how many elements it has)
      return Array.isArray(value);
    }

    // If more than one key is present, or the key doesn't match selectedColumnName, return false
    return false;
  },

  subtractSlackExcludingWeekends(startDate, slackDays){
    let currentDate = new Date(startDate);
    while (slackDays > 0) {
      currentDate.setDate(currentDate.getDate() - 1);
  
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        slackDays--;
      }
    }
    return currentDate;
  },

  handleAllowDate({ selectedDate, setData, startDate, setSataus }) {
    setData((prevData) => { 
      let isFormValidOrNot = false;
      //if this type format is there 00:00:00 GMT+0530 (India Standard Time) that type format will convert in 05:30:00 GMT+0530 (India Standard Time) by bellow modifyDate
      const modifyDate= selectedDate.toString().replace("00:00:00","05:30:00")
      // will convert this in date format
      const selectedISTDate = new Date(AcpService.convertToIST(new Date(modifyDate)));
      const allTasks = prevData?.map((item) => {
        // Convert item[startDate] to a valid date
        const taskStartDate = AcpService.checkIfValidDate(item[startDate])
          ? AcpService.convertToIST(AcpService.convertShortDateToGMT(item[startDate]))
          : AcpService.convertToIST(item[startDate]);

        // Parse Slack value and subtract it excluding weekends
        const slackDays = parseInt(item.Slack);
        const taskSlackAdjustedDate = this.subtractSlackExcludingWeekends(new Date(taskStartDate), slackDays);
        const isAllow = selectedISTDate >= taskSlackAdjustedDate;
        item.allow = isAllow;
        if (!isAllow) {
          isFormValidOrNot = true;
        }
        return item;
      });

      setSataus(isFormValidOrNot); // Update the form status
      return allTasks;
    });
},

handleAllowDatePlanning({ selectedDate, setData, startDate, setSataus }) {
  setData((prevData) => {
    let isFormValidOrNot = false;
    //if this type format is there 00:00:00 GMT+0530 (India Standard Time) that type format will convert in 05:30:00 GMT+0530 (India Standard Time) by bellow modifyDate
    const modifyDate= selectedDate.toString().replace("00:00:00","05:30:00")
    // will convert this in date format
    const selectedISTDate = new Date(AcpService.convertToIST(new Date(modifyDate)));
    const allTasks = prevData?.map((item) => {
      const _startDate = !AcpService.isUBN(item["NewStartDate"]) ? item["NewStartDate"]: item[startDate];
      const taskStartDate = AcpService.checkIfValidDate(_startDate) ? AcpService.convertFormatToUTC(_startDate): AcpService.convertToIST(_startDate);
      const slackDays = parseInt(item.Slack || 0);
      const taskSlackAdjustedDate = this.subtractSlackExcludingWeekends(new Date(taskStartDate), slackDays);
      const isAllow = selectedISTDate >= taskSlackAdjustedDate;
      item.allow = isAllow;
      if (!isAllow) {
        isFormValidOrNot = true;
      }
      return item;
    });
    // Set the form status based on whether all tasks are allowed or not
    setSataus(isFormValidOrNot);
    return allTasks;
  });
},

adjustDateBySlackForTask(task, minDate) {
  let slackDays = parseInt(task?.Slack, 10) || 0;
  const currentDate = new Date(); // Get the current date
  // Move back slackDays, skipping weekends
  while (slackDays > 0) {
    minDate.setDate(minDate.getDate() - 1);
    const dayOfWeek = minDate.getDay();
    // Check if the day is a weekday (Monday-Friday)
    if (dayOfWeek !== 6 && dayOfWeek !== 0) {
      slackDays--;
    }
  }
  // Ensure the adjusted minDate is not earlier than the current date
  if (minDate < currentDate) {
    minDate = currentDate;
  }
  return minDate;
},

adjustDateBySlackForAll(selectedData, isPlanning=false) {
  let validDates = [];
  const currentDate = new Date(); // Get the current date
  // Loop through each task in selectedData
  selectedData.forEach(task => {
    let taskMinDate = isPlanning 
      ? (AcpService.checkIfValidDate(!AcpService.isUBN(task?.NewStartDate) ? task?.NewStartDate : task?.StartDate) ? new Date(AcpService.convertFormatToUTC(!AcpService.isUBN(task?.NewStartDate) ? task?.NewStartDate : task?.StartDate)): new Date(AcpService.convertToIST(!AcpService.isUBN(task?.NewStartDate) ? task?.NewStartDate : task?.StartDate))) 
      : (AcpService.checkIfValidDate(task?.StartDate) ? new Date(AcpService.convertFormatToUTC(task?.StartDate)): new Date(AcpService.convertToIST(task?.StartDate)));
    // Adjust the date for each task's slack
    let adjustedDate = this.adjustDateBySlackForTask(task, taskMinDate);
    // Ensure the adjusted date is not earlier than the current date
    if (adjustedDate < currentDate) {
      adjustedDate = currentDate;
    }
    // Add the adjusted date to the list of valid dates
    validDates.push(adjustedDate);
  });
  // Find the earliest date from all valid dates
  let earliestDate = validDates.reduce((earliest, current) => {
    return earliest.getTime() < current.getTime() ? earliest : current;
  });
  return earliestDate;
},

checkMinDate(selectedData, setMinDate) {
  let minDate = new Date(); // Default value
  const currentDate = new Date(); // Get the current date
  if (selectedData.length > 0) {
    // Calculate the earliest possible valid date by adjusting for slack for all tasks
    minDate = this.adjustDateBySlackForAll(selectedData);
  }
  // Ensure the minDate is not earlier than the current date
  if (minDate < currentDate) {
    minDate = currentDate;
  }
  // Update the minDate in the state
  setMinDate(minDate);
},

checkMinDatePlanning(selectedData, setMinDate) {
  let minDate = new Date(); // Default value
  if (selectedData.length > 0) {
    // Calculate the earliest possible valid date by adjusting for slack for all tasks
    minDate = this.adjustDateBySlackForAll(selectedData,true);
  }
  // Update the minDate in the state
  setMinDate(minDate);
},

  updateQueryParams(key, value, location, navigate) {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(key)) {
      searchParams.set(key, value);
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  },
  parseCustomDate(dateString) {
    // Extract components of the date string
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6) - 1; // JavaScript months are 0-based
    const day = dateString.substring(6, 8);
    const hours = dateString.substring(9, 11);
    const minutes = dateString.substring(11, 13);
    const seconds = dateString.substring(13, 15);

    return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
  },

  checkValidProjectReadinessDate(selectedDate, selectedProjectData) {
    let isFormValidOrNot = false;
    const modifiedProjectData = selectedProjectData?.map((item) => {
      const printerDate = item?.EstimatedAWPrinter
        ? AcpService.formatDate(item?.EstimatedAWPrinter)
        : new Date();
      const minDate = item?.ProjectStartDate ? AcpService.getProjectMinDate(item) : new Date();
      if (selectedDate > printerDate || selectedDate < minDate) {
        item["isAllow"] = false;
        isFormValidOrNot = true;
      } else {
        item["isAllow"] = true;
      }
      return item;
    });
    return {
      formValid: isFormValidOrNot,
      projectData: modifiedProjectData,
    };
  },
  convertArrayToObject(inputArray) {
    // Initialize an empty object to store the combined result
    const result = {};

    // Iterate through each object in the input array
    inputArray?.forEach((item) => {
      // Get each key-value pair in the current object
      for (const key in item) {
        if (item?.hasOwnProperty(key)) {
          // If the key does not exist in the result, add it and assign the value
          if (!result[key]) {
            result[key] = item?.[key];
          } else {
            // If the key already exists in the result, concatenate the values
            result[key] = result?.[key]?.concat(item[key]);
          }
        }
      }
    });

    return result;
  },
  getUniqueValuesObject(obj) {
    // Iterate through each key in the object
    for (const key in obj) {
      // Convert the array to a Set to remove duplicates, then back to an array
      obj[key] = [...new Set(obj[key])];
    }

    return obj;
  },

  sortDates(dateStrings) {
    const normalDates = [];
    const dateRanges = [];

    // Separate normal dates and date ranges
    dateStrings?.forEach((dateString) => {
      if (dateString?.includes("to")) {
        dateRanges.push(dateString);
      } else {
        normalDates.push(dateString);
      }
    });

    // Function to parse date strings into Date objects
    const parseDate = (dateString) => {
      const [day, month, year] = !AcpService.isUBN(dateString) && dateString?.split("-");
      const monthIndex = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ].indexOf(month);
      return new Date(`20${year}`, monthIndex, day); // Assuming 20xx for years
    };

    // Sort normal dates
    normalDates?.sort((a, b) => parseDate(a) - parseDate(b));

    // Sort date ranges based on the start date
    dateRanges?.sort((a, b) => {
      const startA = parseDate(a?.split(" to ")[0]);
      const startB = parseDate(b?.split(" to ")[0]);
      return startA - startB;
    });

    // Combine sorted normal dates and date ranges
    return [...normalDates, ...dateRanges];
  },
  sortTasksByDate(tasks, direction) {
    return tasks?.sort((a, b) => {
      // Function to extract the date for sorting
      const extractDate = (task) => {
        const dateString = task?.Date.includes(" to ") ? task?.Date.split(" to ")[0] : task.Date;
        return new Date(dateString);
      };

      const dateA = extractDate(a);
      const dateB = extractDate(b);

      // Compare dates according to the specified direction
      if (direction === "asc") {
        return dateA - dateB;
      } else if (direction === "desc") {
        return dateB - dateA;
      } else {
        throw new Error("Invalid sorting direction. Use 'ascending' or 'descending'.");
      }
    });
  },
  getMonthDay(dateString) {
    // date should be in YYYYMMDD
    // Ensure the input is exactly 8 characters and only contains digits
    if (typeof dateString === "string") {
      const extractedMonth = dateString.slice(4, 6); // Extract characters at index 4-5 for the month
      const day = dateString.slice(6, 8); // Extract characters at index 6-7 for the day
      const month = parseInt(extractedMonth, 10);
      return { month, day };
    } else {
      throw new Error("Invalid date format. Please use YYYYMMDD.");
    }
  },
  findUniqueByKey(arr, key) {
    const uniqueValues = new Set(); // To keep track of unique values for the specified key
    return arr?.filter((obj) => {
      if (!uniqueValues.has(obj[key])) {
        uniqueValues.add(obj[key]);
        return true; // Keep this object in the result
      }
      return false; // Exclude duplicate key values
    });
  },

  convertToDate(input) {
    const months = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6,
        Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12
    };

    // Validate the input format
    const regex = /^(\d{2})-(\w{3})-(\d{2})$/;
    const match = input.match(regex);
    if (!match) {
        throw new Error("Invalid date format. Expected format is dd-mmm-yy.");
    }

    const day = parseInt(match[1], 10);
    const monthStr = match[2];
    const yearSuffix = parseInt(match[3], 10);
    
    // Check if month is valid
    if (!months[monthStr]) {
        throw new Error("Invalid month abbreviation.");
    }

    const month = months[monthStr]-1;
    
    // Determine full year
    const year = (yearSuffix >= 50) ? 1900 + yearSuffix : 2000 + yearSuffix;

    // Validate day
    const date = new Date(year, month, day);

    if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day) {
        throw new Error("Invalid date value.");
    }

    return date;
  },

   getWeekdayDate(){
    const today = new Date();
  
    // If today is Saturday (6), move to Sunday (add 1 day)
    if (today.getDay() === 6) {
      today.setDate(today.getDate() + 1);
    }
    // If today is Friday (5), move to Sunday (add 2 day)
    if (today.getDay() === 5) {
      today.setDate(today.getDate() + 2);
    }
    // Return the adjusted date as a Date object
    return today;
  },
  getDateWeekdays(today){
 // const today = new Date();  // Start with today's date
  let count = 0;  // Counter to track weekdays
  let targetDate = new Date(today);  // Copy of today's date for calculation

  // Loop until we add 14 weekdays
  while (count < 14) {
    targetDate.setDate(targetDate.getDate() + 1);  // Move to the next day

    // Check if the current day is not Saturday (6) or Sunday (0)
    if (targetDate.getDay() !== 0 && targetDate.getDay() !== 6) {
      count++;  // Increment weekday counter
    }
  }

  // Return the target Date object (no formatting)
  return targetDate;
}

};
