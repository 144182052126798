import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Col, Nav, Tooltip, OverlayTrigger } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withCookies } from "react-cookie";
import { isEmpty } from "lodash";

import PgLogo from "../../assets/images/logo.svg";
import ExpandImg from "../../assets/images/expand.svg";
import "./index.scss";
import { updateUser } from "../../apis/userApi";
import { commonMenu, getUrl, getImage } from "./NavItems";
import { userUpdateAction } from "../../store/actions/userActions";
import { deleteAllCookies } from "./cleanUp";
import { updateMode } from "../../store/actions/ProjectSetupActions";
import { resetStore } from "../../store/actions/resetStoreActions";
import { persistor, store } from "../../store/store";
import { hasPublicRoutes, hasRequiredAuthData } from "../../Utils/helpers";
import { getDropDownValues } from "../../apis/getDropDownValues";
import { dropDownValuesAction } from "../../store/actions/dropDownValuesAction";
import { getUserDetails } from "../../apis/getUserDetails";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../common/NavigationPrompt";

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const User = useSelector((state) => state.UserReducer);
  const {
    userDetails: { accessible_menu: availableMenu, AccessGroup },
  } = useSelector((state) => state.UserDetailsReducer);
  const [isToggle, setIsToggle] = useState(JSON.parse(sessionStorage.getItem("sideBarOpen")));
  const sidebarWidth = isToggle ? "100px" : "68px";
  const [roleBasedMenu, setRoleBasedMenu] = useState([]);
  const [pmPresent, setPmPresent] = useState(false);
  const [show, setShow] = useState(false);
  const isValid = hasRequiredAuthData();
  const [showDialog, setShowDialog] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);

  // Integrate the useUnsavedChangesWarning hook
  const { isDirty, markDirty, markClean } = useUnsavedChangesWarning();


  const fileteredAvailableMenu = availableMenu?.filter((ele) => {
    return ele.menuName !== "N/A";
  });

  // To check PM is there in the list
  const isProjectManager = useCallback(() => {
    if (AccessGroup !== undefined) {
      const result = fileteredAvailableMenu?.find((ele) => {
        return ele.menuName.toLowerCase() === "CreateProject".toLowerCase();
      });

      if (result) {
        setPmPresent(true);
      } else {
        setPmPresent(false);
      }
    }
  }, [pmPresent]);

  const menuLength = useMemo(() => {
    if (roleBasedMenu !== undefined) {
      if (roleBasedMenu.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }, [roleBasedMenu]);

  const getFilteredMenu = () => {
    const menu = fileteredAvailableMenu?.map((nav) => {
      return { ...nav, url: getUrl(nav.menuName), img: getImage(nav.menuName) };
    });
    setRoleBasedMenu(menu);
    return menu;
  };

  // Added this effect to make sure if required data dispatched
  useEffect(() => {
    if (!hasPublicRoutes() && isValid) {
      (async () => {
        if (isEmpty(User?.userInformation)) {
          const allDropDown = await getDropDownValues();
          dispatch(dropDownValuesAction(allDropDown));
          getFilteredMenu();

          // NOTE: Temporary exchanging data from localStorage to avoid breakable changes, later its needs to optimise
          dispatch(
            userUpdateAction({
              role: localStorage.getItem("role"),
              region: localStorage.getItem("region"),
              bu: localStorage.getItem("bu"),
              userid: localStorage.getItem("userId"),
              username: localStorage.getItem("userName"),
              firstName: localStorage.getItem("FirstName"),
              lastName: localStorage.getItem("LastName"),
            })
          );
          if (isEmpty(availableMenu)) {
            await getUserDetails(localStorage.getItem('userId'));
          }
        }
      })();
    }
  }, [User, availableMenu, isValid]);

  useEffect(() => {
    getFilteredMenu();
    isProjectManager();
  }, [User, availableMenu, isValid]);

  const toggleSidebar = () => {
    sessionStorage.setItem("sideBarOpen", JSON.stringify(!isToggle));
    setIsToggle(!isToggle);
  };


  const handleLogout = async (event) => {
    event.preventDefault();
    store.dispatch(resetStore());
    localStorage.clear();
    sessionStorage.clear();
    persistor.purge();
    updateUser("", "");
    deleteAllCookies();
    setTimeout(() => {
      window.location = process.env.REACT_APP_LOGOUT_URL;
    }, 500);
  };

  const updateCreateMode = (url) => {
    // markDirty(); // Mark as dirty when updating create mode
    if (url === "/projectSetup") {
      dispatch(updateMode("create"));
    }
  };

  const handleNavLinkClick = (url) => {
    return (event) => {
      const unsavedChanges=JSON.parse(localStorage.getItem('unsavedChanges'));
      if (unsavedChanges) {
        event.preventDefault();
        setShowDialog(true);
        setPendingNavigation(url);
        return;
      }

      navigate(url);
      markClean();
      updateCreateMode(url);
    };
  };

  const confirmNavigation = () => {
    navigate(pendingNavigation);
    setShowDialog(false);
    markClean();
    updateCreateMode(pendingNavigation);
  };

  const cancelNavigation = () => {
    setShowDialog(false);
    setPendingNavigation(null);
  };

  return (
    <>
      <Col
        sm={4}
        className="left-grid"
        style={{
          width: sidebarWidth,
          padding: 0,
          transition: "width 0.3s ease-in-out",
        }}
      >
        <div className="sidebar">
          <img
            src={ExpandImg}
            alt="logos"
            onClick={toggleSidebar}
            className={!isToggle ? "toggle-collapsed" : "toggle-expanded"}
          />
          <div className="logo">
            <img src={PgLogo} alt="PG LOGO" className={`pg-logo ${!isToggle && "toggle-logo"}`} />
            <p className={!isToggle ? "titleCollapse" : "titleExpand"}>Artwork Agility Suite</p>
          </div>

          <Nav
            style={{
              paddingTop: !isToggle && "15px",
              maxHeight: "470px",
              overflowX: "hidden",
              overflowY: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="left-nav-wrapper">
              {roleBasedMenu &&
                roleBasedMenu?.map((item, index) => {
                  return (
                    <OverlayTrigger
                      key={index}
                      placement={"right"}
                      // show={!isToggle}
                      onEnter={() => setShow(!show)}
                      overlay={
                        <Tooltip id={`tooltip-${index}`}>
                          <span style={{ color: "#003da5" }}> {item.displayName}</span>.
                        </Tooltip>
                      }
                    >
                      <NavLink to={item.url} onClick={handleNavLinkClick(item.url)}>
                        <img src={item.img} alt="menuImage" />
                        {isToggle ? <div> {item.displayName}</div> : ""}
                      </NavLink>
                    </OverlayTrigger>
                  );
                })}
              {commonMenu?.map((item, index) => {
                return (
                  <OverlayTrigger
                    key={index}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${index}`}>
                        <span style={{ color: "#003da5" }}> {item.name}</span>.
                      </Tooltip>
                    }
                  >
                    <NavLink
                      to={item.url}
                      onClick={item.url === "" || item.url === '/' ? handleLogout : handleNavLinkClick(item.url)}
                      exact={item.url === "" || item.url === '/' ? "true" : "false"}
                    >
                      <img src={item.img} alt="menuImage" />
                      {isToggle ? <div> {item.name}</div> : ""}
                    </NavLink>
                  </OverlayTrigger>
                );
              })}
            </div>
          </Nav>
        </div>
      </Col>
      <UnsavedChangesDialog visible={showDialog} onConfirm={confirmNavigation} onCancel={cancelNavigation} />
    </>
  );
};

export default withCookies(SideBar);
