/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { Toast } from "primereact/toast";
import LoadingOverlay from "react-loading-overlay-ts";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BreadCrumb } from "primereact/breadcrumb";
import { addLocale } from "primereact/api";
import "../index.scss";
import "../../Tasks/TaskList/index.scss";

import TabsComponent from "./tabsComponent";
import CapacityManagementHeader from "./CapacityManagementHeader";
import {
  getAcpBookingData,
  updateRTATasks,
  updateEmptyErrorData,
  planningMassUpdate,
  massUpdateACPPlanningBooking,
  getACPDashboardProjectsRequiringPM,
  getAcpBookingBacklogData,
  getPMPicklistData,
  updatePMPicklistData,
  getACPDashboardAlerts,
} from "../../../apis/acpApi";
import AcpBookingDatatable from "../Booking/AcpBookingDatatable";
import AcpFooter from "./AcpFooter";
import { rowGroups, rowGroupsForDashboard } from "../constant";
import AcpDialog from "./AcpDialog";
import AcpCommonDialog from "./AcpCommonDialog";
import AcpPlanningDialog from "./Dialog/AcpPlanningDialog";
import AcceptBVEEndDateDialog from "../BveLoop/AcceptBVEEndDateDialog";
import CapacityManagementPlaningHeader from "../Planning/Header/CapacityManagementPlaningHeader";
import CapacityManagementSubHeader from "../Planning/CapacityManagementSubHeader";
import CdNavigation from "../Planning/Summary/CdNavigation";
import TaskAndProjectListNavigation from "../Planning/TaskAndProjectListNavigation";

import ACPBookingChart from "../Booking/ACPBookingChart";
import { AcpService } from "../../../service/ACPService";
import {
  acpPlanningSetDefaultFilters,
  setAcpPlanningTaskDraftData,
  setAcpPlanningProjectDraftData,
} from "../../../store/actions/AcpPlanningActions";
import AcpDashboard from "../Dashboard";
import { createDraftData } from "../../../service/ACPplanningService";
import {
  bookingRtaPayload,
  createPayloadData,
  creatMassUpdatePayload,
  getBuRegionList,
  removeAllLocalStorage,
  getValidBUList,
} from "../../../service/DashboardService";
//import { usePriorityNew } from "../../../Utils/GetRolePriority";
import UnAuthorizedComponent from "../../UnAuthorized/UnAuthorizedComponent";
import { isRoleMatch } from "../../../utils";
import { AcpCommonService } from "../../../service/AcpCommonService";

function CapacityManagement(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const user_group = useSelector((state) => state?.UserDetailsReducer?.userDetails?.UserGroup);
  const {
    selectedBU,
    selectedDropdownValue,
    userDetails: { AccessGroup },
  } = useSelector((state) => state?.UserDetailsReducer);

  const { acpBookingData, acpDashboardProjectsRequiringPM, RTATasksUpdateError } = useSelector(
    (state) => state?.AcpBookingReducer
  );
  const projectSetup = useSelector((state) => state?.ProjectSetupReducer);
  const newStartDatesSaved = localStorage.getItem("NewStartDatesSaved");
  const selectedProjectDetails = projectSetup.selectedProject;
  const pmNameSaved = localStorage.getItem("PmNameSaved");
  const User = useSelector((state) => state.UserReducer);
  const locationPath = location?.pathname;
  const url = locationPath?.split("/");
  const [checkReadWriteAccess, setUserAccess] = useState(false);
  // const checkReadWriteAccess = isUserAccess; //usePriorityNew(location?.pathname?.split("/")?.[1]);
  const isCapacityManager = isRoleMatch(AccessGroup, ["CapacityManager"]);
  const toast = useRef(null);
  const [reset, setReset] = useState(false);
  const [Headers, setHeaders] = useState([]);
  const [filters, setFilters] = useState([]);
  const [loader, setLoader] = useState(false);
  const userInformation = User?.userInformation;
  const [visible, setVisible] = useState(false);
  const [editedRow, setEditedRow] = useState({});
  const [isSearch, isSearchSet] = useState(false);
  const [actionFlag, setActionFlag] = useState([]);
  const [expandAll, setExpandAll] = useState(true);
  const [bookingData, setBookingData] = useState([]);
  const [errorMsgArr, setErrorMsgArr] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allEditedRows, setAllEditedRows] = useState([]);
  const [bookingTableData, setBookingTableData] = useState([]);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [editedRowPanelBottom, setEditedRowPanelBottom] = useState([]);
  const [selectedRowsPanelBottom, setSelectedRowsPanelBottom] = useState([]);
  const [allEditedRowsPanelBottom, setAllEditedRowsPanelBottom] = useState([]);
  const [dashboardPMTableData, setDashboardPMTableData] = useState([]);
  const [showAcpBookingDialog, setShowAcpBookingDialog] = useState(false);
  const [showAcpPlanDialog, setShowAcpPlanDialog] = useState(false);
  const [bookingTableFilters, setBookingTableFilters] = useState({});
  const [ShowCustomizedView, setShowCustomizedView] = useState(false);
  const [tabName, setTabName] = useState(url[2] !== undefined ? url[2] : url[1]);
  const [capacity, setCapacity] = useState(0);
  const [planningfilters, setPlanningfilters] = useState([]);
  const [isTaskData, setIsTaskData] = useState([]);
  const [isProjectData, setIsProjectData] = useState([]);
  const [isProjectListTab, setIsProjectListTab] = useState(false);
  const [saveFlag, setSaveFlag] = useState(true);
  const [applyFilters, setApplyFilters] = useState(false);
  const [exportTaskData, setExportTaskData] = useState([]);
  const [exportProjectData, setExportProjectData] = useState([]);
  const [planningData, setPlanningData] = useState({ isTask: true, planningData: [] });
  const [saveChangesOption, setSaveChangesOption] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [loadTaskTable, setLoadTaskTable] = useState(false);
  const [loadProjectTable, setLoadProjectTable] = useState(false);
  const [activeTable, setActiveTable] = useState("TaskList");
  const [task, setTask] = useState();
  const [supplierValue, setSupplierValue] = useState();
  const [chartData, setChartData] = useState([]);
  const [chartClicked, setChartClicked] = useState(false);
  const [updatedBookingData, setUpdatedBookingData] = useState([]);
  const [range, setRange] = useState("");
  const [acpFilters, setAcpFilters] = useState({});
  const [clearAllFilter, setClearAllFilter] = useState(false);
  const [modalType, setModalType] = useState("");
  const [actionType, setActionType] = useState("");
  const [suppliers, setSuppliers] = useState([]);
  const [resetMultiFilter, setResetMultiFilter] = useState(false);

  const [showDraftDialog, setShowDraftDialog] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [changingTab, setChangingTab] = useState();
  const [onSave, setOnSave] = useState(false);
  const [dateChangeValue, setDateChangeValue] = useState([]);

  const { acpPlanningFilters, taskDraftChanges, projectDraftChanges } = useSelector(
    (state) => state?.AcpPlanningReducer
  );
  const [customizeViewFields, setCustomizeViewFields] = useState(
    localStorage.getItem("customizeViewFieldsBooking")
  );
  //placehoder for selected tasks and project from the table
  const [selectedTask, setSelectedTask] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [backlogData, setBacklogData] = useState([]);

  //set bu-region List
  const [buRegionList, setBuRegionList] = useState([]);
  const [validBu, setValidBu] = useState([]);
  //const [selectedBU, setSelectedBU]=useState({});

  //pickListData
  const [pmPicklistData, setPMPicklistData] = useState([]);
  const [dashboardAlerts, setDashboardAlerts] = useState([]);

  //TODO: Below code should be refactored to make the component better
  const customizeViewFieldsBooking = localStorage.getItem("customizeViewFieldsBooking");
  const customizeViewFieldsParsed = customizeViewFieldsBooking && JSON.parse(customizeViewFields);
  const SavedFieldRefs = localStorage.getItem("_selectedFieldsRefCurrentBooking");
  const SavedFieldRefsParsed = SavedFieldRefs && JSON.parse(SavedFieldRefs);
  const sortedColumnBooking = localStorage.getItem("sortedColumnBooking");
  const sortedColumnBookingParsed = sortedColumnBooking && JSON.parse(sortedColumnBooking);
  const SavedFieldPlaningRefs = localStorage.getItem("_selectedFieldsRefCurrentPlanning");
  const SavedFieldPlaningRefsParsed = SavedFieldPlaningRefs && JSON.parse(SavedFieldPlaningRefs);
  const sortedColumnPlaning = localStorage.getItem("sortedColumnPlanning");
  const sortedColumnPlaningParsed = sortedColumnPlaning && JSON.parse(sortedColumnPlaning);
  const SavedFieldRefsDashboardPanelTop = localStorage.getItem(
    "_selectedFieldsRefCurrentDashboardPanelTop"
  );
  const SavedFieldRefsDashboardPanelTopParsed =
    SavedFieldRefsDashboardPanelTop && JSON.parse(SavedFieldRefsDashboardPanelTop);
  const sortedColumnDashboardPanelTop = localStorage.getItem("sortedColumnDashboardPanelTop");
  const SavedFieldRefsDashboardPanelBottom = localStorage.getItem(
    "_selectedFieldsRefCurrentDashboardPanelBottom"
  );
  const SavedFieldRefsDashboardPanelBottomParsed =
    SavedFieldRefsDashboardPanelBottom && JSON.parse(SavedFieldRefsDashboardPanelBottom);
  const sortedColumnDashboardPanelBottom = localStorage.getItem("sortedColumnDashboardPanelBottom");
  addLocale("en", {
    firstDayOfWeek: 1,
  });
  const handleChartData = (data) => {
    setChartData(data);
  };
  const dashboardPMTableDataRefresh = () => {
    setLoader(true);
    fetchData();
  };
  useEffect(() => {
    if (acpPlanningFilters) {
      setFilters(acpPlanningFilters);
      setAcpFilters(acpPlanningFilters);
    }
  }, [acpPlanningFilters, activeTable]);

  useEffect(() => {
    const isUserAccess = isRoleMatch(AccessGroup, [
      "CapacityManager",
      "ProjectManager",
      "TaskOwner",
    ]);
    setUserAccess(isUserAccess);
  }, [AccessGroup]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Perform actions before the component unloads
      event.preventDefault();
      setShowErrorDialog(false);
      updateEmptyErrorData();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    setTabName(url[2] !== undefined ? url[2] : url[1]);
  }, [url]);

  useEffect(() => {
    setExpandAll(true);
    if (checkReadWriteAccess && validBu?.length) {
      fetchAcpBookingBacklog();
    }
    removeAllLocalStorage();
    isSearchSet(false);
    if (tabName === "planning") {
      setActiveTable("TaskList");
    }
  }, [tabName]);

  useEffect(() => {
    dispatch(acpPlanningSetDefaultFilters());
    if (taskDraftChanges?.length) {
      setSaveChangesOption(true);
      setSaveFlag(false);
    }
  }, []);

  useEffect(() => {
    if (planningfilters?.length > 0 || isTaskData?.length > 0 || isProjectData?.length > 0) {
      const taskData = planningfilters?.length > 0 ? planningfilters : isTaskData;
      const projectData = planningfilters?.length > 0 ? planningfilters : isProjectData;
      if (!isProjectListTab) {
        setExportProjectData([]);
        setExportTaskData(taskData);
      } else if (isProjectListTab) {
        setExportTaskData([]);
        setExportProjectData(projectData);
      }
    }
  }, [planningfilters, isTaskData, isProjectData]);

  const handleMessClick = (type) => {
    setModalType(type);
    setShowAcpBookingDialog(true);
  };
  const handleMessUpPlan = () => {
    setShowAcpPlanDialog(true);
  };

  const fetchAcpBookingBacklog = async () => {
    if (tabName === "booking") {
      setLoader(true);
      const budetails = selectedBU?.length ? selectedBU : validBu;
      const response = await getAcpBookingBacklogData(userInformation, budetails);
      if (response?.length) {
        setLoader(false);
        setBacklogData(response);
      } else {
        setLoader(false);
        setBacklogData([]);
      }
    }
  };

  const fetchData = async () => {
    const budetails = selectedBU?.length ? selectedBU : validBu;
    if (
      (buRegionList?.length && buRegionList?.length === 1) ||
      (buRegionList?.length &&
        buRegionList?.length > 1 &&
        !AcpService.isUBN(selectedDropdownValue) &&
        Object.keys(selectedDropdownValue)?.length)
    ) {
      if (tabName === "booking") {
        await getAcpBookingData(userInformation, budetails, bookingTableFilters);
      }
      if (tabName === "dashboard") {
        setLoader(true);
        await getACPDashboardProjectsRequiringPM(budetails);
        getPMPicklistData(budetails).then((data) => {
          setPMPicklistData(data);
        });
        setLoader(false);
        const res = await getACPDashboardAlerts(budetails);
        if (res && Object.keys(res)?.length) {
          setDashboardAlerts(res);
        } else {
          setDashboardAlerts([]);
        }
      }
      // if (tabName === "bveLoop") {
      //   let { offTractBVELoop, onTractBVELoop } = await getBVELoopData(userInformation, budetails);
      //   let { tempOnTrack, tempOffTrack } = createGroupForBVELoop(offTractBVELoop, onTractBVELoop);
      //   if (tempOnTrack?.length && tempOffTrack?.length) {
      //     setBVELoopTableData([...tempOnTrack, ...tempOffTrack]);
      //   }
      // }
    }
    setAllEditedRowsPanelBottom([]);
    setAllEditedRows([]);
    setLoader(false);
  };

  const onSubmit = async () => {
    setLoader(true);
    setSaveChangesOption(false);
    setDateChangeValue([]);
    const preSelectedRows =
      tabName === "dashboard"
        ? pmNameSaved && JSON.parse(pmNameSaved)
        : newStartDatesSaved && JSON.parse(newStartDatesSaved);

    const updatedSelectedData =
      tabName === "dashboard"
        ? allEditedRows.map((el) => {
            return { ...el, PmName: el.PmName?.name };
          })
        : allEditedRows.map((el) => {
            return {
              ...el,
              StartDate:
                !AcpService.isUBN(el?.StartDate) && !AcpService.checkIfValidDate(el?.StartDate)
                  ? AcpService.formatDate(el?.StartDate)
                  : AcpService.convertDate(new Date(el?.StartDate)),
            };
          }).filter(item => item?.StartDate !== "Invalid date"); //Filters this data array to include only those objects or elements where the StartDate property is not Invalid date, for AWM-3217
    const firstData =
      actionType === "inlineUpdate"
        ? updatedSelectedData
        : preSelectedRows?.length
        ? preSelectedRows
        : [];
    const secondData =
      actionType === "inlineUpdate"
        ? preSelectedRows?.length
          ? preSelectedRows
          : []
        : updatedSelectedData;
    const filteredSelectedData = AcpService.findUniqueObjects(firstData, secondData, "UniqueID");
    if (tabName === "dashboard") {
      const finalData = filteredSelectedData?.map((el) => {
        return {
          AWMProjectID: el?.AWMProjectID,
          PM: el?.PM?.name ? el?.PM?.name : el?.PmName,
        };
      });
      let obj = {};
      obj["NewPM"] = finalData;
      const pmStatus = await updatePMPicklistData(obj);

      if (pmStatus) {
        setTimeout(() => {
          fetchData();
        }, 1000);
      }
      localStorage.setItem("PmNameSaved", JSON.stringify([]));
      setLoader(false);
    } else if (tabName === "planning") {
      massUpdatePlanningData();
    } else {
      const rtaData = filteredSelectedData.filter((el) => el?.POANumber);
      const massUpdateData = filteredSelectedData.filter((el) => !el?.POANumber);
      const finalData = creatMassUpdatePayload(massUpdateData);
      let massUpdateStatus = false;
      if (finalData?.length > 0) {
        const { response } = await massUpdateACPPlanningBooking({ NewStartDates: finalData });
        if (response?.status === 200) {
          massUpdateStatus = true;
          localStorage.setItem("NewStartDatesSaved", JSON.stringify([]));
        }
      }
      let submitRows = [];
      let rtaStatus = false;
      if (rtaData?.length) {
        submitRows = bookingRtaPayload(rtaData, tabName);
        const submitData = {
          RTATasksPage: submitRows,
        };
        await updateRTATasks(submitData);
        rtaStatus = true;
      }
      if (massUpdateStatus || rtaStatus) {
        localStorage.removeItem("NewStartDatesSaved");
        setAllEditedRows([]);
        clearFilter();
        setTimeout(() => {
          fetchData();
          fetchAcpBookingBacklog();
        }, 1000);
      }
    }
    setVisible(false);
  };
  const [resetKey, setResetKey] = useState(0);

  const handleChildClick = (isClicked) => {
    if (!isClicked) {
      clearFilter();
      setResetKey((prev) => prev + 1);
    }
  };
  const massUpdatePlanningData = async () => {
    const taskData = isTaskData?.map((item) => {
      return {
        ...item,
        NewStartDate:
          !AcpService.isUBN(item?.NewStartDate) && AcpService.checkIfValidDate(item?.NewStartDate)
            ? AcpCommonService.convertToDate(item?.NewStartDate)
            : item?.NewStartDate,
      };
    }).filter((item) => item?.NewStartDate !== null); // Filters this array to include only those objects or elements where the NewStartDate property is not null, for AWM-3217
    const projectData = isProjectData?.map((item) => {
      return {
        ...item,
        NewStartDate:
          !AcpService.isUBN(item?.NewStartDate) && AcpService.checkIfValidDate(item?.NewStartDate)
            ? AcpCommonService.convertToDate(item?.NewStartDate)
            : item?.NewStartDate,
      };
    }).filter((item) => item?.NewStartDate !== null); // Filters this array to include only those objects or elements where the NewStartDate property is not null, for AWM-3217
    const massUpdatetaskData = taskData?.filter((el) => !el?.POANumber);
    const rtaData = taskData?.filter((el) => el?.POANumber);
    if (taskData?.length && activeTable === "TaskList") {
      if (rtaData?.length) {
        const payload = createPayloadData(rtaData);
        if (payload?.length > 0) {
          const submitData = {
            RTATasksPage: payload,
          };
          let res = await updateRTATasks(submitData);
          if (res) {
            setLoadTaskTable(true);
            setIsTaskData([]);
          } else {
            setLoadTaskTable(true);
            setIsTaskData([]);
          }
          //need to handle error scenario
        } else {
          setLoadTaskTable(true);
          setIsTaskData([]);
        }
      }
      if (massUpdatetaskData?.length) {
        planningMassUpdate(massUpdatetaskData, "Task").then(() => {
          setLoadTaskTable(true);
          setIsTaskData([]);
        });
      }
    } else if (projectData?.length) {
      planningMassUpdate(projectData, "Project").then(() => {
        setLoadProjectTable(true);
        setIsProjectData([]);
      });
    }
  };

  const checkDraftChanges = (item) => {
    if (
      (isTaskData?.length && isTaskData?.length > taskDraftChanges?.length) ||
      (isProjectData?.length && isProjectData?.length > projectDraftChanges?.length)
    ) {
      setShowDraftDialog(true);
      setChangingTab(item);
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (RTATasksUpdateError === "null") {
      return;
    } else if (RTATasksUpdateError?.length > 0) {
      setShowErrorDialog(true);
      setErrorMsgArr(RTATasksUpdateError);
    } else if (RTATasksUpdateError?.length === 0 && validBu?.length && userInformation?.userid) {
      setLoader(true);
      fetchData();
      setErrorMsgArr([]);
    }
  }, [RTATasksUpdateError, validBu?.length, userInformation?.userid]);

  const handleCancel = () => {
    setDateChangeValue([]);
    if (tabName === "planning") {
      setSaveFlag(false);
    }
    setReset(!reset);
    localStorage.setItem("NewStartDatesSaved", JSON.stringify([]));
  };

  const onSaveAsDraft = () => {
    let data = activeTable === "TaskList" ? isTaskData : isProjectData;
    if (data.length > 0) {
      const draftData = createDraftData(data);
      if (draftData.length > 0) {
        if (activeTable === "TaskList") {
          dispatch(setAcpPlanningTaskDraftData(draftData));
        } else {
          dispatch(setAcpPlanningProjectDraftData(draftData));
        }
      }
    }
    setSaveFlag(false);
  };

  const orderByGroupOrder = (data) => {
    return data?.sort(function (a, b) {
      return a?.GroupOrder - b?.GroupOrder;
    });
  };

  useEffect(() => {
    if (tabName === "dashboard") {
      setDashboardPMTableData(acpDashboardProjectsRequiringPM);
    } else {
      let temp = [];
      if (acpBookingData?.length) {
        let groups = Object.keys(acpBookingData[0]);
        groups.forEach((group) => {
          acpBookingData[0][group].forEach((obj) => {
            obj["Group"] = group;
            obj["GroupOrder"] = rowGroups.filter((grp) => {
              return grp.id === group;
            })?.[0]?.order;
            temp.push(obj);
          });
        });
      }
      setBookingTableData(orderByGroupOrder(temp));
    }
  }, [acpBookingData, acpDashboardProjectsRequiringPM, tabName]);

  useEffect(() => {
    if (
      (tabName &&
        bookingTableFilters?.endDate &&
        bookingTableFilters?.Date &&
        validBu?.length &&
        userInformation?.userid) ||
      (!AcpService.isUBN(selectedDropdownValue) &&
        Object.keys(selectedDropdownValue)?.length &&
        tabName === "dashboard")
    ) {
      // setLoader(true);
      fetchData();
    }
  }, [
    tabName,
    bookingTableFilters?.callAPi,
    validBu?.length,
    userInformation?.userid,
    selectedDropdownValue,
  ]);

  useEffect(() => {
    if (user_group?.length) {
      const validBUList = getValidBUList(user_group);
      setValidBu(validBUList);
      const burRegionData = getBuRegionList(user_group);
      setBuRegionList(burRegionData);
    }
  }, [user_group]);

  const onSearchClick = () => {
    isSearchSet(!isSearch);
  };

  const childFunc = useRef(null);
  // remove All filters and reset all the state
  const clearFilter = () => {
    setClearAllFilter(true);
    isSearchSet(false);
    if(tabName !== "planning"){
      handleCancel();
    }
   if (tabName === "dashboard") {
      removeAllLocalStorage([
        "sortedColumnDashboardPanelTop",
        "sortedColumnDashboardPanelBottom",
        "_selectedFieldsRefCurrentDashboardPanelTop",
        "_selectedFieldsRefCurrentDashboardPanelBottom",
      ]);
    }
    else if(tabName==="booking")
    {
      setLoader(true);
      childFunc?.current?.clear();
      removeAllLocalStorage([
        "sortedColumnBooking",
        "_selectedFieldsRefCurrentBooking",
      ]);
      setTimeout(() => {
        setLoader(false);
      }, 100);
    } 
    else {
      setLoader(true);
      childFunc?.current?.clear();
      setCustomizeViewFields(null);
      removeAllLocalStorage([
        "sortedColumnBooking",
        "_selectedFieldsRefCurrentBooking",
      ]);
      setTimeout(() => {
        setLoader(false);
      }, 100);
    }
  };

  // const isFilterEnabled =
  //   frozenCoulmns?.length || filters?.length || sortData?.length || isSearch;

  const _items = [
    { label: "Capacity Management" },
    { label: tabName.charAt(0).toUpperCase() + tabName.slice(1) },
  ];

  const selectedBarData = (data, flag) => {
    if (data === undefined) return;
    setUpdatedBookingData(data);
    setChartClicked(flag);
  };

  const showReportToast = (message, type) => {
    if (toast.current) {
      toast.current.show({
        severity: !AcpService.isUBN(type) ? type : "success",
        summary: message,
        detail: !AcpService.isUBN(type) ? "Error!" : "Successful!",
        life: 3000,
      });
    }
  };

  const itemsData = [
    {
      name: "dashboard",
      tabNameForDisplay: "Dashboard",
      component: tabName === "dashboard" && (
        <>
          {checkReadWriteAccess ? (
            <LoadingOverlay active={loader} spinner text="">
              <div className="capacity-main">
                <div className="projectSetupParent project-plan-wrapper acp-main">
                  <div className="col">
                    {selectedProjectDetails !== undefined && (
                      <CapacityManagementHeader
                        header=""
                        range={range}
                        loader={loader}
                        headers={Headers}
                        tabName={tabName}
                        filterFLag={false}
                        capacity={capacity}
                        buRegionList={buRegionList}
                        userGroup={user_group}
                        validatedBuList={validBu}
                        selectedDropdownValue={selectedDropdownValue}
                        editedRow={editedRow}
                        setLoader={setLoader}
                        allData={bookingData}
                        expandAll={expandAll}
                        suppliers={suppliers}
                        isDashboardPage={true}
                        isTreeTableFlag={true}
                        isResetEnabled={false}
                        actionType={actionType}
                        actionFlag={actionFlag}
                        setVisible={setVisible}
                        clearFilters={() => {}}
                        saveSettings={() => {}}
                        demand={chartData?.length}
                        bookingData={bookingData}
                        CustomizeViewFlag={false}
                        clearFilter={clearFilter}
                        hideCustomizedView={true}
                        ResetToDefaultFlag={false}
                        setSuppliers={setSuppliers}
                        selectedRows={selectedRows}
                        setEditedRow={setEditedRow}
                        setExpandAll={setExpandAll}
                        rightSideHeadersOnly={true}
                        onSearchClick={onSearchClick}
                        allEditedRows={allEditedRows}
                        setActionType={setActionType}
                        handleMessClick={handleMessClick}
                        setAllEditedRows={setAllEditedRows}
                        customizeViewFields={customizeViewFields}
                        editedRowPanelBottom={editedRowPanelBottom}
                        setShowCustomizedView={setShowCustomizedView}
                        setBookingTableFilters={setBookingTableFilters}
                        setCustomizeViewFields={setCustomizeViewFields}
                        selectedRowsPanelBottom={selectedRowsPanelBottom}
                        setEditedRowPanelBottom={setEditedRowPanelBottom}
                        allEditedRowsPanelBottom={allEditedRowsPanelBottom}
                        setSelectedRowsPanelBottom={setSelectedRowsPanelBottom}
                        setAllEditedRowsPanelBottom={setAllEditedRowsPanelBottom}
                        isFilterEnabled={
                          isSearch ||
                          SavedFieldRefsDashboardPanelTopParsed?.length ||
                          SavedFieldRefsDashboardPanelBottomParsed?.length
                        }
                        onChildClick={handleChildClick}
                      />
                    )}
                  </div>
                  <div>
                    <AcpDashboard
                      pmPicklistData={pmPicklistData}
                      reset={reset}
                      loader={loader}
                      ref={childFunc}
                      Headers={Headers}
                      tabName={tabName}
                      isSearch={isSearch}
                      fetchData={fetchData}
                      setLoader={setLoader}
                      editedRow={editedRow}
                      expandAll={expandAll}
                      suppliers={suppliers}
                      actionType={actionType}
                      setHeaders={setHeaders}
                      isSearchSet={isSearchSet}
                      bookingData={dashboardPMTableData}
                      dashboardAlerts={dashboardAlerts}
                      setSuppliers={setSuppliers}
                      setEditedRow={setEditedRow}
                      chartClicked={chartClicked}
                      selectedRows={selectedRows}
                      setExpandAll={setExpandAll}
                      setActionType={setActionType}
                      allEditedRows={allEditedRows}
                      setActionFlag={setActionFlag}
                      setChartData={handleChartData}
                      clearAllFilter={clearAllFilter}
                      setBookingData={setBookingData}
                      setSelectedRows={setSelectedRows}
                      rowGroups={rowGroupsForDashboard}
                      setAllEditedRows={setAllEditedRows}
                      bookingTableData={dashboardPMTableData}
                      setClearAllFilter={setClearAllFilter}
                      ShowCustomizedView={ShowCustomizedView}
                      updatedBookingData={updatedBookingData}
                      setBookingTableData={setBookingTableData}
                      bookingTableFilters={bookingTableFilters}
                      customizeViewFields={customizeViewFields}
                      checkReadWriteAccess={isCapacityManager}
                      showAcpBookingDialog={showAcpBookingDialog}
                      editedRowPanelBottom={editedRowPanelBottom}
                      setShowCustomizedView={setShowCustomizedView}
                      setBookingTableFilters={setBookingTableFilters}
                      setCustomizeViewFields={setCustomizeViewFields}
                      setShowAcpBookingDialog={setShowAcpBookingDialog}
                      selectedRowsPanelBottom={selectedRowsPanelBottom}
                      setEditedRowPanelBottom={setEditedRowPanelBottom}
                      allEditedRowsPanelBottom={allEditedRowsPanelBottom}
                      setSelectedRowsPanelBottom={setSelectedRowsPanelBottom}
                      setAllEditedRowsPanelBottom={setAllEditedRowsPanelBottom}
                      selectedDropdownValue={selectedDropdownValue}
                      resetKey={resetKey}
                    />
                  </div>
                </div>
                <AcpFooter
                  onSubmit={onSubmit}
                  handleCancel={handleCancel}
                  checkReadWriteAccess={isCapacityManager}
                  formValid={
                    (pmNameSaved && JSON.parse(pmNameSaved)?.length) || allEditedRows?.length
                  }
                />
              </div>
            </LoadingOverlay>
          ) : (
            AccessGroup && AccessGroup?.length && <UnAuthorizedComponent nowrapper={true} />
          )}
        </>
      ),
    },
    {
      name: "planning",
      tabNameForDisplay: "Planning",
      component: tabName === "planning" && (
        <>
          {checkReadWriteAccess ? (
            <div className="acp-planning-page">
              <div className="projectSetupParent project-plan-wrapper acp-main">
                <div className="headerWrapper">
                  {filters && (
                    <CapacityManagementPlaningHeader
                      setAcpFilters={setAcpFilters}
                      acpFilters={acpFilters}
                      setTask={setTask}
                      setSupplierValue={setSupplierValue}
                      isProjectListTab={isProjectListTab}
                      PM={userInformation?.userid}
                      showDraftDialog={showDraftDialog}
                      setShowDraftDialog={setShowDraftDialog}
                      activeTable={activeTable}
                      isTaskData={isTaskData}
                      isProjectData={isProjectData}
                      applyFilters={applyFilters}
                      setClearAllFilter={setClearAllFilter}
                      setApplyFilters={setApplyFilters}
                      UserGroup={selectedBU?.length ? selectedBU : validBu}
                      onChildClick={handleChildClick}
                    />
                  )}
                  <div className="capacityManagement">
                    <CapacityManagementSubHeader
                      handleMessUpPlan={handleMessUpPlan}
                      planningData={planningData}
                      isTaskData={exportTaskData}
                      isProjectData={exportProjectData}
                      isSearch={isSearch}
                      selectedTask={selectedTask}
                      selectedProject={selectedProject}
                      isSearchSet={isSearchSet}
                      activeTable={activeTable}
                      setClearAllFilter={setClearAllFilter}
                      isFilterEnabled={isSearch || SavedFieldPlaningRefsParsed?.length}
                      onChildClick={handleChildClick}
                    />
                  </div>
                </div>
                {filters && (
                  <CdNavigation
                    activeTable={activeTable}
                    filters={acpFilters}
                    isTaskData={isTaskData}
                    isProjectData={isProjectData}
                    setIsTaskData={setIsTaskData}
                    setIsProjectData={setIsProjectData}
                    task={task}
                    supplierValue={supplierValue}
                    PM={userInformation.userid}
                    UserGroup={selectedBU?.length ? selectedBU : validBu}
                    reset={reset}
                    taskData={taskData}
                    setTaskData={setTaskData}
                    setSaveFlag={setSaveFlag}
                    saveFlag={saveFlag}
                  />
                )}

                {/* C-D, Capacity, Demand Tables inside above component*/}
                <TaskAndProjectListNavigation
                  setIsTaskData={setIsTaskData}
                  isTaskData={isTaskData}
                  isProjectData={isProjectData}
                  setIsProjectData={setIsProjectData}
                  setIsProjectListTab={setIsProjectListTab}
                  isSearch={isSearch}
                  activeTable={activeTable}
                  saveChangesOption={saveChangesOption}
                  setSaveChangesOption={setSaveChangesOption}
                  loadTaskTable={loadTaskTable}
                  setLoadTaskTable={setLoadTaskTable}
                  setLoadProjectTable={setLoadProjectTable}
                  setPlanningData={setPlanningData}
                  loadProjectTable={loadProjectTable}
                  acpFilters={acpFilters}
                  task={task}
                  setTask={setTask}
                  supplierValue={supplierValue}
                  reset={reset}
                  setReset={setReset}
                  setActiveTable={setActiveTable}
                  filters={planningfilters}
                  setFilters={setPlanningfilters}
                  selectedTask={selectedTask}
                  setSelectedTask={setSelectedTask}
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  PM={userInformation?.userid}
                  UserGroup={selectedBU?.length ? selectedBU : validBu}
                  setFormValid={setFormValid}
                  saveFlag={saveFlag}
                  setSaveFlag={setSaveFlag}
                  showDraftDialog={showDraftDialog}
                  setShowDraftDialog={setShowDraftDialog}
                  tabName={tabName}
                  clearAllFilter={clearAllFilter}
                  setClearAllFilter={setClearAllFilter}
                  taskData={taskData}
                  setTaskData={setTaskData}
                  setApplyFilters={setApplyFilters}
                  changingTab={changingTab}
                  setChangingTab={setChangingTab}
                  setTabName={setTabName}
                  checkReadWriteAccess={isCapacityManager}
                  resetKey={resetKey}
                  setDateChangeValue={setDateChangeValue}
                />

                {/* Task List and Project List Tables inside above component*/}
              </div>
              <AcpFooter
                onSubmit={() => onSubmit()}
                onSaveAsDraft={() => onSaveAsDraft()}
                handleCancel={() => handleCancel()}
                checkReadWriteAccess={saveChangesOption}
                formValid={
                  dateChangeValue?.length &&
                  (isTaskData?.length ||
                    isProjectData?.length ||
                    taskDraftChanges?.length ||
                    projectDraftChanges?.length)
                }
                identifier={"PlanningPage"}
                saveFlag={saveFlag}
              />
            </div>
          ) : (
            AccessGroup && AccessGroup?.length && <UnAuthorizedComponent nowrapper={true} />
          )}
        </>
      ),
    },
    {
      name: "booking",
      tabNameForDisplay: "Booking",
      component: tabName === "booking" && (
        <>
          {checkReadWriteAccess ? (
            <>
              <div className="booking-header">
                <BreadCrumb
                  home={{}}
                  model={_items}
                  className="booking-breadcrumb"
                  separatorIcon={<i className="pi pi-angle-right"></i>}
                />
              </div>
              <LoadingOverlay active={loader} spinner text="">
                <div className="capacity-main">
                  <div className="projectSetupParent project-plan-wrapper acp-main">
                    <div className="col">
                      {selectedProjectDetails !== undefined && (
                        <CapacityManagementHeader
                          header=""
                          range={range}
                          loader={loader}
                          headers={Headers}
                          tabName={tabName}
                          buRegionList={buRegionList}
                          userGroup={user_group}
                          selectedDropdownValue={selectedDropdownValue}
                          validatedBuList={validBu}
                          filterFLag={false}
                          capacity={capacity}
                          suppliers={suppliers}
                          editedRow={editedRow}
                          setLoader={setLoader}
                          allData={bookingData}
                          expandAll={expandAll}
                          isTreeTableFlag={true}
                          isResetEnabled={false}
                          actionFlag={actionFlag}
                          setVisible={setVisible}
                          actionType={actionType}
                          clearFilters={() => {}}
                          saveSettings={() => {}}
                          demand={chartData?.length}
                          bookingData={bookingData}
                          CustomizeViewFlag={false}
                          clearFilter={clearFilter}
                          ResetToDefaultFlag={false}
                          selectedRows={selectedRows}
                          setSuppliers={setSuppliers}
                          setEditedRow={setEditedRow}
                          setExpandAll={setExpandAll}
                          onSearchClick={onSearchClick}
                          allEditedRows={allEditedRows}
                          setActionType={setActionType}
                          clearAllFilter={clearAllFilter}
                          handleMessClick={handleMessClick}
                          setAllEditedRows={setAllEditedRows}
                          resetMultiFilter={resetMultiFilter}
                          setResetMultiFilter={setResetMultiFilter}
                          customizeViewFields={customizeViewFields}
                          setShowCustomizedView={setShowCustomizedView}
                          setBookingTableFilters={setBookingTableFilters}
                          setCustomizeViewFields={setCustomizeViewFields}
                          isFilterEnabled={isSearch || SavedFieldRefsParsed?.length}
                          onChildClick={handleChildClick}
                        />
                      )}
                    </div>
                    {true ? (
                      <>
                        <ACPBookingChart
                          reset={reset}
                          setRange={setRange}
                          chartData={chartData}
                          UserGroup={selectedBU?.length ? selectedBU : validBu}
                          backlogData={backlogData}
                          setCapacity={setCapacity}
                          PM={userInformation?.userid}
                          selectedBarData={selectedBarData}
                          resetMultiFilter={resetMultiFilter}
                          setResetMultiFilter={setResetMultiFilter}
                          bookingTableFilters={bookingTableFilters}
                        />
                        <AcpBookingDatatable
                          reset={reset}
                          loader={loader}
                          ref={childFunc}
                          Headers={Headers}
                          tabName={tabName}
                          isSearch={isSearch}
                          rowGroups={rowGroups}
                          fetchData={fetchData}
                          setLoader={setLoader}
                          editedRow={editedRow}
                          expandAll={expandAll}
                          suppliers={suppliers}
                          setHeaders={setHeaders}
                          actionType={actionType}
                          backlogData={backlogData}
                          isSearchSet={isSearchSet}
                          bookingData={bookingData}
                          setSuppliers={setSuppliers}
                          setEditedRow={setEditedRow}
                          chartClicked={chartClicked}
                          selectedRows={selectedRows}
                          setExpandAll={setExpandAll}
                          allEditedRows={allEditedRows}
                          setActionType={setActionType}
                          setActionFlag={setActionFlag}
                          setChartData={handleChartData}
                          setBookingData={setBookingData}
                          clearAllFilter={clearAllFilter}
                          setSelectedRows={setSelectedRows}
                          setAllEditedRows={setAllEditedRows}
                          bookingTableData={bookingTableData}
                          resetMultiFilter={resetMultiFilter}
                          setClearAllFilter={setClearAllFilter}
                          ShowCustomizedView={ShowCustomizedView}
                          updatedBookingData={updatedBookingData}
                          setResetMultiFilter={setResetMultiFilter}
                          setBookingTableData={setBookingTableData}
                          bookingTableFilters={bookingTableFilters}
                          customizeViewFields={customizeViewFields}
                          checkReadWriteAccess={isCapacityManager}
                          showAcpBookingDialog={showAcpBookingDialog}
                          setShowCustomizedView={setShowCustomizedView}
                          setBookingTableFilters={setBookingTableFilters}
                          setCustomizeViewFields={setCustomizeViewFields}
                          setShowAcpBookingDialog={setShowAcpBookingDialog}
                          onSave={onSave}
                          resetKey={resetKey}
                          setFormValid={setFormValid}
                          setDateChangeValue={setDateChangeValue}
                          chartData={chartData}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <AcpFooter
                    loader={loader}
                    onSubmit={onSubmit}
                    handleCancel={handleCancel}
                    checkReadWriteAccess={isCapacityManager}
                    formValid={
                      (newStartDatesSaved && JSON.parse(newStartDatesSaved)?.length) ||
                      dateChangeValue?.length
                    }
                  />
                </div>
              </LoadingOverlay>
            </>
          ) : (
            AccessGroup && AccessGroup?.length && <UnAuthorizedComponent nowrapper={true} />
          )}
        </>
      ),
    },
    {
      name: "bveLoop",
      tabNameForDisplay: "BVE Loop",
      component: tabName === "bveLoop" && (
        <div className="projectSetupParent project-plan-wrapper acp-main">
          <div className="breadCrumbParent">
            <div className="row">
              <div className="col">
                <div>BVE Loop</div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      name: "reports",
      tabNameForDisplay: "Reports",
      component: tabName === "reports" && (
        <div className="projectSetupParent project-plan-wrapper acp-main">
          <div className="breadCrumbParent">
            <div className="row">
              <div className="col">
                <div>Reports</div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="content-layout">
      <Toast ref={toast} />
      {visible && (
        <AcceptBVEEndDateDialog
          visible={visible}
          setVisible={setVisible}
          selectedRows={selectedRows}
          onSubmit={onSubmit}
        />
      )}
      {showErrorDialog && (
        <AcpDialog
          setLoader={setLoader}
          showErrorDialog={showErrorDialog}
          setShowErrorDialog={setShowErrorDialog}
          errorMsgArr={errorMsgArr}
        />
      )}
      {showAcpBookingDialog && (
        <AcpCommonDialog
          tabName={tabName}
          modalType={modalType}
          suppliers={suppliers}
          fetchData={fetchData}
          actionType={actionType}
          setMainLoader={setLoader}
          setEditedRow={setEditedRow}
          pmPicklistData={pmPicklistData}
          setActionType={setActionType}
          allEditedRows={allEditedRows}
          showReportToast={showReportToast}
          setSelectedData={setSelectedRows}
          showAcpBookingDialog={showAcpBookingDialog}
          setShowAcpBookingDialog={setShowAcpBookingDialog}
          user_group={selectedBU?.length ? selectedBU : validBu}
          dashboardPMTableDataRefresh={dashboardPMTableDataRefresh}
          selectedData={AcpCommonService.findUniqueByKey(selectedRows, "UniqueID")}
          onSave={onSave}
          setOnSave={setOnSave}
        />
      )}
      {showAcpPlanDialog && (
        <AcpPlanningDialog
          setLoader={setLoader}
          showAcpPlanDialog={showAcpPlanDialog}
          setShowAcpPlanDialog={setShowAcpPlanDialog}
          selectedTask={selectedTask}
          setSaveChangesOption={setSaveChangesOption}
          selectedProject={selectedProject}
          setFormValid={setFormValid}
          setLoadTaskTable={setLoadTaskTable}
          setLoadProjectTable={setLoadProjectTable}
          setSelectedProject={setSelectedProject}
          setSelectedTask={setSelectedTask}
          activeTable={activeTable}
          setIsTaskData={setIsTaskData}
          setIsProjectData={setIsProjectData}
          saveFlag={saveFlag}
          setSaveFlag={setSaveFlag}
        />
      )}
      <div className="tabular-view acp">
        <TabsComponent
          className="booking-tabs"
          tabName={tabName}
          items={itemsData}
          setTabName={setTabName}
          disabled={
            (tabName === "dashboard" &&
              buRegionList?.length &&
              buRegionList?.length > 1 &&
              !AcpService.isUBN(selectedDropdownValue) &&
              !Object.keys(selectedDropdownValue)?.length) ||
            (tabName === "dashboard" && validBu?.length === 0)
          }
          basePage={url[1]}
          checkDraftChanges={checkDraftChanges}
        />
      </div>
    </div>
  );
}

export default CapacityManagement;
