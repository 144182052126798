import React, { useEffect, useState, useMemo } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { TabView, TabPanel } from "primereact/tabview";
import { Form } from "react-bootstrap";
import "./index.scss";
import { Accordion } from "react-bootstrap";
import { checkIfGreyIcon, checkIfRedIcon, createPMPObject } from "../utils";
import { DMTabValuesAction } from "../../../store/actions/DMTabValuesActions";
import {
  onSubmitDependencyMappingAction,
  getDependencyMappingDetails,
  createNewGaBriefTask,
  requestCICAndRequestCicMatrix,
  getDependencyMappingTabDetails,
} from "../../../apis/dsbpApi";
import FooterButtons from "../../AWMJobs/DesignJobs/FooterButtons";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomHeader from "./CustomHeader";
import toggleOff from "../../../assets/images/toggleOff.svg";
import toggleOn from "../../../assets/images/toggleOn.svg";
import deleteIcon from "../../../assets/images/deleteIcon.svg";
import { generateTaskName, getRequestText } from "../utils";
import { Button } from "primereact/button";
import { useLocation, useParams } from "react-router-dom";
import editName from "../../../assets/images/editName.svg";
import { Tooltip } from "primereact/tooltip";
import ManageDependency from "../../ManageDependency";
import { getProjectValues } from "../../../store/actions/ProjectSetupActions";
import AlertIcon from "../../common/AlertIcon/AlertIcon";
import { dsbpConstant } from "../constant";
import CICInfo from "../../../assets/images/CICInfo.svg";
import DsbpCommonPopup from "../DsbpCommonPopup";
import { useUnsavedChangesWarning,UnsavedChangesDialog } from "../../common/NavigationPrompt";
import { usePriorityNew } from "../../../Utils/GetRolePriority";

const errorMessage = "Valid PMP# is 8 digits";

const DMPMPSpecificTabView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { ProjectID, assignee } = useParams();
  const { dmTabValuesData } = useSelector((state) => state.DMTabValuesReducer);
  // const { selectedProject } = useSelector((state) => state.ProjectSetupReducer);
  const [storesTabList, setStoresTabDataList] = useState(dmTabValuesData);
  const [filteredDataList, setFilteredDataList] = useState(dmTabValuesData);
  const [tabPanelList, setTabPanelList] = useState(1);
  const [cdpt, setCDPT] = useState("");
  const [rdt, setRDT] = useState("");
  const [iq, setIQ] = useState("");
  const [cicNeeded, setCICNeeded] = useState("");
  const [pmpDesign, setPMPDesign] = useState("");
  const [pmpLayout, setPMPLayout] = useState("");
  const [otherRef, setOtherRef] = useState("");
  const [gaBrief, setGaBrief] = useState("");
  const [cicMatrix, setCICMatrix] = useState("");
  const [formData, setFormData] = useState({});
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [isToggleClick, setToggleClick] = useState(false);
  const [selectedTab, setSelectedTabData] = useState({});
  const [loader, setLoader] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const { dmTabAttributesData } = useSelector((state) => state.DMTabValuesReducer);
  const [dmTabData, setDmTabData] = useState(dmTabAttributesData);
  const [showManageDependency, setShowManageDependency] = useState(false);

  const [selectedProjectDetails, setSelectedProjectDetails] = useState([]);
  const projectSetup = useSelector((state) => state.ProjectSetupReducer);
  const [changedData, setChangedData] = useState([]);

  const { isDirty, markDirty, markClean } = useUnsavedChangesWarning();
  const { userInformation } = useSelector((state) => state.UserReducer);
  const currentUrl = useLocation().pathname.split("/");
  const isUserAccess = assignee === userInformation?.userid; 
  const accessSecurityMatrix = usePriorityNew(currentUrl[1]);
  const finalPermission = isUserAccess ? isUserAccess : accessSecurityMatrix;


  
  const dropdownMI = dmTabAttributesData?.DMMappingData?.map((item) => item?.DSBP_PMP_PIMaterialID);
  const dropdownInitiateID = dmTabAttributesData?.DMMappingData?.map((item) => item?.DSBP_InitiativeID);

  const fetchProjectData = useMemo(() => {
    if (projectSetup?.status === 200) {
      return projectSetup.projectData?.ArtworkAgilityProjects || {};
    }
    return {};
  }, [projectSetup?.status, projectSetup?.projectData?.ArtworkAgilityProjects]);
  useEffect(() => {
    if (fetchProjectData) {
      setSelectedProjectDetails(fetchProjectData?.[0] || []);
    }
  }, [fetchProjectData]);

  useEffect(() => {
    setSelectedTabData(dmTabValuesData[tabPanelList]);
    fetchDependencyMappingTabDetails(
      dmTabValuesData?.[tabPanelList]?.description?.DSBP_InitiativeID,
      dmTabValuesData?.[tabPanelList]?.description?.DSBP_PMP_PIMaterialID,
      dmTabValuesData?.[tabPanelList]?.description?.DSBP_PMP_PIMaterialNumber,
      ProjectID
    );
  }, [tabPanelList]);

  // const selectedProjectDetails = projectSetup.selectedProject;
  const CICs = selectedProjectDetails?.CICs;

  const { Artwork_Brand, Artwork_Category, Project_Name, Artwork_SMO } = selectedProjectDetails;

  const task_Name = generateTaskName(Artwork_Brand, Artwork_Category, Project_Name, Artwork_SMO);


  const navigateToDSBP = () => {
    navigate(`/${currentUrl?.[1]}/dependencyMapping/${ProjectID}`);
  };

  useEffect(() => {
    if (ProjectID) dispatch(getProjectValues(ProjectID));
  }, [ProjectID]);

  useEffect(() => {
    setFilteredDataList(dmTabValuesData);
  }, [dmTabValuesData]);

  useEffect(() => {
    setTabPanelList(dmTabValuesData?.length - 1);
    dispatch(DMTabValuesAction(dmTabValuesData));
  }, []);

  useEffect(() => {
    if (tabPanelList >= storesTabList?.length) {
      setTabPanelList(storesTabList.length - 1);
    }
    storesTabList !== undefined && dispatch(DMTabValuesAction(storesTabList));
    setSelectedTabData(dmTabValuesData[tabPanelList]);
    if (dmTabValuesData[tabPanelList]) {
      const selectedTabData = dmTabValuesData[tabPanelList];
      if (selectedTabData?.description !== undefined) {
        setCDPT(selectedTabData?.description?.Color_Development_and_Print_Trial);
        setRDT(selectedTabData?.description?.Regional_Design_Template);
        setIQ(selectedTabData?.description?.Ink_Qualification);
        setCICNeeded(selectedTabData?.description?.AWM_CIC_Needed);
        setPMPDesign(selectedTabData?.description?.AWM_Supporting_PMP_Design);
        setPMPLayout(selectedTabData?.description?.AWM_Supporting_PMP_Layout);
        setOtherRef(selectedTabData?.description?.AWM_Other_Reference);
        setGaBrief(selectedTabData?.description?.AWM_GA_Brief);
        setCICMatrix(selectedTabData?.description?.AWM_CIC_Matrix_Toggle);
        setFormData({
          DSBP_InitiativeID: selectedTabData?.description?.DSBP_InitiativeID,
          DSBP_PMP_PIMaterialID: selectedTabData?.description?.DSBP_PMP_PIMaterialID,
          DSBP_PMP_PIMaterialNumber: selectedTabData?.description?.DSBP_PMP_PIMaterialNumber,
          AWM_CICNeeded: selectedTabData?.description?.AWM_CIC_Needed,
          AWM_SupportingPMPLayoutPage: selectedTabData?.description?.AWM_Supporting_PMP_Layout,
          AWM_SupportingPMPDesignPage: selectedTabData?.description?.AWM_Supporting_PMP_Design,
          AWM_OtherReference: selectedTabData?.description?.AWM_Other_Reference,
          AWM_GABrief: selectedTabData?.description?.AWM_GA_Brief || " ",
          AWM_CIC_Matrix_Toggle:selectedTabData?.description?.AWM_CIC_Matrix_Toggle || " ",
          // Regional_Design_Template: selectedTabData?.description?.Regional_Design_Template,
          // Color_Development_and_Print_Trial: selectedTabData?.description?.Color_Development_and_Print_Trial,
          // Ink_Qualification: selectedTabData?.description?.Ink_Qualification,
        });
      }
    }
    // setFormData({});
  }, [storesTabList, tabPanelList]);

  useEffect(() => {
    let filteredData =
      // save should be disable if all the three fields(AWM_SupportingPMPLayout,AWM_SupportingPMPDesign,AWM_OtherReference) are empty when cic needed selected is No
      formData.updated &&
      ((formData.AWM_CICNeeded === "No" &&
        formData.AWM_SupportingPMPLayoutPage === "" &&
        formData.AWM_OtherReference === "" &&
        formData.AWM_SupportingPMPDesignPage === "") ||
        (formData.AWM_OtherReference !== "" &&
          formData.AWM_CICNeeded === "N/A" &&
          formData?.AWM_OtherReference?.length !== 8) ||
        (formData?.AWM_CICNeeded === "Yes" &&
          (formData?.AWM_GABrief === " " || formData?.AWM_GABrief === "")));
    if (filteredData !== undefined) {
      let setSubmitEnable = filteredData ? true : false;
      setIsSubmitEnabled(setSubmitEnable);
    } else {
      setIsSubmitEnabled(true);
    }
  }, [formData]);

  useEffect(() => {
    setSelectedTabData(dmTabValuesData[tabPanelList]);
    if (dmTabValuesData[tabPanelList]) {
      const selectedTabData = dmTabValuesData[tabPanelList];
      if (selectedTabData?.description !== undefined) {
        setCDPT(selectedTabData?.description?.Color_Development_and_Print_Trial);
        setRDT(selectedTabData?.description?.Regional_Design_Template);
        setIQ(selectedTabData?.description?.Ink_Qualification);
        setCICNeeded(selectedTabData?.description?.AWM_CIC_Needed);
        setPMPDesign(selectedTabData?.description?.AWM_Supporting_PMP_Design);
        setPMPLayout(selectedTabData?.description?.AWM_Supporting_PMP_Layout);
        setOtherRef(selectedTabData?.description?.AWM_Other_Reference);
        setGaBrief(selectedTabData?.description?.AWM_GA_Brief);
        setCICMatrix(selectedTabData?.description?.AWM_CIC_Matrix_Toggle)
        setFormData({
          DSBP_InitiativeID: selectedTabData?.description?.DSBP_InitiativeID,
          DSBP_PMP_PIMaterialID: selectedTabData?.description?.DSBP_PMP_PIMaterialID,
          DSBP_PMP_PIMaterialNumber: selectedTabData?.description?.DSBP_PMP_PIMaterialNumber,
          AWM_CICNeeded: selectedTabData?.description?.AWM_CIC_Needed,
          AWM_SupportingPMPLayoutPage: selectedTabData?.description?.AWM_Supporting_PMP_Layout,
          AWM_SupportingPMPDesignPage: selectedTabData?.description?.AWM_Supporting_PMP_Design,
          AWM_OtherReference: selectedTabData?.description?.AWM_Other_Reference,
          AWM_GABrief: selectedTabData?.description?.AWM_GA_Brief || " ",
          // Regional_Design_Template: selectedTabData?.description?.Regional_Design_Template,
          // Color_Development_and_Print_Trial: selectedTabData?.description?.Color_Development_and_Print_Trial,
          // Ink_Qualification: selectedTabData?.description?.Ink_Qualification,
          // AWM_CIC_Matrix:selectedTabData?.description?.AWM_CIC_Matrix,
        });
      }
    }
    // setFormData({});
  }, [dmTabValuesData]);

  useEffect(() => {
    if (dmTabValuesData) {
      setStoresTabDataList(dmTabValuesData || []);
    }
  }, [dmTabValuesData]);
  useEffect(()=>{
    if(!isSubmitEnabled){
      markDirty()
    }else{
      markClean()
    }
  },[isSubmitEnabled]);
  const confirmNavigation = () => {
    markClean();
    setShowDialog(false);
    handleDelete(pendingNavigation);
  };

  const cancelNavigation = () => {
    setShowDialog(false);
  };
  const handleNewGaBrief = async () => {
    let formData = {
      NewGABTask: "Yes",
      AWM_Project_ID: ProjectID,
      AWM_Task_ID: "",
      Project_Name: selectedProjectDetails?.Project_Name,
      BU: selectedProjectDetails?.BU,
      Region: selectedProjectDetails?.Project_region,
    };
    let res = await createNewGaBriefTask(formData);
  };

  // const handleCDPTChange = (e) => {
  //   setCDPT(e.target.value);

  //   setFormData({
  //     ...formData,
  //     DSBP_CDPT_Page: e.target.value,
  //   });
  // };

  // const handleRDTchange = (e) => {
  //   setRDT(e.target.value);

  //   setFormData({
  //     ...formData,
  //     rdt,
  //   });
  // };

  // const handleIQChange = (e) => {
  //   setIQ(e.target.value);

  //   setFormData({
  //     ...formData,
  //     DSBP_IQ_Page: e.target.value,
  //   });
  // };

  const handleCICNeededChange = (e) => {
    markDirty();
    setCICNeeded(e.target.value);
    if (e.target.value !== "No") {
      setPMPDesign("");
      setPMPLayout("");
    }
    setFormData({
      ...formData,
      AWM_CICNeeded: e.target.value,
      AWM_SupportingPMPDesignPage: createPMPObject(null),
      AWM_SupportingPMPLayoutPage: createPMPObject(null),
      updated: true,
    });
  };

  const handlePmpDesignChange = (e) => {
    const selectedOption = e.target.selectedOptions[0];
    const dropdownMI = selectedOption.getAttribute("data-dropdownMI");
    const dropdownInitiateID = selectedOption.getAttribute("data-dropdownInitiateID");
    setPMPDesign(e.target.value);
    const updatedFormData = {
      ...formData,
      AWM_SupportingPMPLayoutPage: typeof formData?.AWM_SupportingPMPLayoutPage === "object" ? formData?.AWM_SupportingPMPLayoutPage : createPMPObject(formData?.AWM_SupportingPMPLayoutPage),

      AWM_SupportingPMPDesignPage: e.target.value === "" ? "" : createPMPObject(`${e.target.value}-${dropdownMI}-${dropdownInitiateID}`),
      updated: true,
    };
    // delete updatedFormData.AWM_SupportingPMPDesign;
    setFormData(updatedFormData);
  };
  const handlePmpLayoutChange = (e) => {
    const selectedOption = e.target.selectedOptions[0];
    const dropdownMI = selectedOption.getAttribute("data-dropdownMI");
    const dropdownInitiateID = selectedOption.getAttribute("data-dropdownInitiateID");
    setPMPLayout(e.target.value);
    const updatedFormData = {
      ...formData,
      AWM_SupportingPMPDesignPage: typeof formData?.AWM_SupportingPMPDesignPage === "object" ?
      formData?.AWM_SupportingPMPDesignPage : createPMPObject(formData?.AWM_SupportingPMPDesignPage),

      AWM_SupportingPMPLayoutPage: e.target.value === "" ? "" : createPMPObject(`${e.target.value}-${dropdownMI}-${dropdownInitiateID}`),
      updated: true,
    };
    // delete updatedFormData.AWM_SupportingPMPLayout;
    setFormData(updatedFormData);
  };
  const handleOtherRefChange = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    // Limit the input to 8 characters
    if (inputValue.length <= 8) {
      setOtherRef(inputValue);
      setFormData({
        ...formData,
        AWM_OtherReference: inputValue,
        updated: true,
      });
    }
  };

  const handleGABriefChange = async (e) => {
    setGaBrief(e.target.value);

    setFormData({
      ...formData,
      AWM_GABrief: e.target.value,
      updated: true,
    });
  };

  const handleCancel = () => {
    return navigateToDSBP();
  };

  const updateMappingTabValuesData = (updatedNewData) => {
    let submittionData = {};
    submittionData = {
      tabHeader: selectedTab.tabHeader,
      description: updatedNewData && updatedNewData[0],
      groupCIC:selectedTab?.groupCIC
    };
    const indexToUpdate = dmTabValuesData.findIndex(
      (tab) => tab.tabHeader === submittionData.tabHeader
    );
    if (indexToUpdate !== -1) {
      // Create a copy of the dmTabValuesData array
      const updateMappingTabValuesData = [...dmTabValuesData];

      // Replace the element at the index with the selectedTab object
      updateMappingTabValuesData[indexToUpdate] = submittionData;

      // Update the state with the updated array
      setStoresTabDataList(updateMappingTabValuesData);
    }
  };

  const saveCICAndCicMatrixRequest = async (formData, reqId) => {
    setLoader(true);
    let selectedData = selectedTab?.description;
    let requestFormData = null;
    if (reqId === 2) {
      requestFormData = {
        AWMProjectID: ProjectID,
        RequestCICList: [
          {
            DSBP_InitiativeID: selectedData?.DSBP_InitiativeID,
            DSBP_PMP_PIMaterialID: selectedData?.DSBP_PMP_PIMaterialID,
            DSBP_PMP_PIMaterialNumber: selectedData?.DSBP_PMP_PIMaterialNumber,
            Task_Name: "CIC_" + selectedData?.DSBP_PMP_PIMaterialNumber,
          },
        ],
      };
    }
    if (reqId === 1) {
      let groupName = selectedData?.AWM_CIC_Matrix;

      let filterMappingDataGroupWise = dmTabData.DMMappingData.filter(
        (ele) => ele.AWM_CIC_Matrix === groupName && ele
      );
     
      let requestCICList = [];
      filterMappingDataGroupWise.map((group) => {
        const POACreated = group?.RTA_POANumber !== "" ? true : false;
        const POAData = POACreated
          ? `_${group?.RTA_POANumber}_${group?.RTA_POADescription}`
          : ``;
          let UCIC_TaskName =`CIC_${group?.DSBP_PMP_PIMaterialNumber}${POAData}`;
        let ObjectForGroup = {};
        ObjectForGroup["DSBP_InitiativeID"] = group.DSBP_InitiativeID;
        ObjectForGroup["DSBP_PMP_PIMaterialID"] = group.DSBP_PMP_PIMaterialID;
        ObjectForGroup["DSBP_PMP_PIMaterialNumber"] = group.DSBP_PMP_PIMaterialNumber;
        ObjectForGroup["UCIC_TaskName"] = UCIC_TaskName;
        requestCICList.push(ObjectForGroup);
      });

      requestFormData = {
        AWMProjectID: ProjectID,
        Task_Names: `CICM_${groupName}_${task_Name}${
          selectedData?.DSM_PMP_PMPPackagingComponentType
            ? "_" + selectedData?.DSM_PMP_PMPPackagingComponentType
            : ""
        }`,
        RequestCICList: requestCICList,
      };
    }

    let resp = await requestCICAndRequestCicMatrix(requestFormData);
    // Call GET API of dependency mapping
    const { dependencyTableData, isRDTData, isIQData, isCDPTData, isGABrifData } =
      await getDependencyMappingDetails(ProjectID);

    const tableData = fetchData(dependencyTableData, isRDTData, isIQData, isCDPTData, isGABrifData);

    let updatedNewTabData = tableData?.filter(
      (data) => data.DSBP_PMP_PIMaterialID === selectedTab.description.DSBP_PMP_PIMaterialID
    );

    updateMappingTabValuesData(updatedNewTabData);
    // setFormData({});
    setLoader(false);
  };

  const fetchDependencyMappingTabDetails = async (
    InitiativeID,
    PIMaterialID,
    PIMaterialNumber,
    projectId,
    reqId
  ) => {
    setLoader(true);
    const { response } = await getDependencyMappingTabDetails(
      InitiativeID,
      PIMaterialID,
      PIMaterialNumber,
      projectId
    );
    if (response) {
      const data = [
        ...(response?.FieldsList ?? []),
        ...(response?.PMPFPCData?.[0]?.FieldsList ?? []),
        ...(response?.PMPFPCData?.[0]?.PMPFPCFormulaData?.[0]?.FieldsList ?? [])
      ];

      let isChangedData = data
        .map((obj) => {
          if (obj["IsChanged"] == "True") {
            return obj;
          }
        })
        .filter((ele) => ele != null);

      setChangedData(isChangedData);
    }
    setLoader(false);
  };

  const onSubmit = async () => {
    setDeleteFlag(false);
    markClean();
    setLoader(true);
    let selectedData = selectedTab?.description?.AWM_CIC_Matrix;
    formData.AWM_GABrief = formData?.AWM_GABrief?.length ? formData?.AWM_GABrief : "";
    setFormData(formData);

    const DSBP_RDT_Page = [];
    dmTabData?.RDTData?.forEach((data) => {
      rdt?.forEach((val) => {
        if (data.AWM_Design_Job_ID === val) {
          DSBP_RDT_Page.push({
            Design_Job_Name: data.AWM_Design_Job_Name,
            Design_Job_ID: data.AWM_Design_Job_ID,
          });
        }
      });
    });
    formData["DSBP_RDT_Page"] = DSBP_RDT_Page;

    const DSBP_CDPT_Page = [];
    dmTabData?.CDPTPageData?.find((data) => {
      cdpt?.forEach((val) => {
        if (data.AWM_Design_Job_ID === val) {
          DSBP_CDPT_Page.push({
            Design_Job_Name: data.AWM_Design_Job_Name,
            Design_Job_ID: data.AWM_Design_Job_ID,
          });
        }
      });
    });
    formData["DSBP_CDPT_Page"] = DSBP_CDPT_Page;

    const DSBP_IQ_Page = [];
    dmTabData?.IQData?.forEach((data) => {
      iq?.forEach((val) => {
        if (data.AWM_Design_Job_ID === val) {
          DSBP_IQ_Page.push({
            Design_Job_Name: data.AWM_Design_Job_Name,
            Design_Job_ID: data.AWM_Design_Job_ID,
          });
        }
      });
    });

    formData["DSBP_IQ_Page"] = DSBP_IQ_Page;
    formData["Task_Name"] = "Request_CIC_" + selectedTab?.description?.DSBP_PMP_PIMaterialID;
    formData["AWMProjectID"] = ProjectID;

    // group name
    formData["AWM_CICMatrix"] = selectedTab?.description?.AWM_CIC_Matrix || "";
    
    if (cicNeeded === "Yes") {
      formData["AWM_OtherReference"] = "";
    }
    if (cicMatrix === "CIC MATRIX ONLY") {
      //cic matrix type
      formData["CICMatrix"] = cicMatrix;
      formData["Action"] = "";
      formData["Task_Name"] = "Request_CIC_" + selectedTab?.description?.DSBP_PMP_PIMaterialID;
    }

    if (cicMatrix === "CIC MATRIX AND CIC") {
      formData["CICMatrix"] = cicMatrix;
      formData["Action"] = "";
      formData["Task_Name"] = `CICM_${selectedTab?.description?.AWM_CIC_Matrix}_${task_Name}${
        selectedTab?.description?.DSM_PMP_PMPPackagingComponentType
          ? "_" + selectedTab?.description?.DSM_PMP_PMPPackagingComponentType
          : ""
      }`;
    }
    if (isToggleClick) {
      delete formData.Task_Name;
    }

    if (deleteFlag) {
      formData["CICMatrix"] = "";
      formData["AWM_CICMatrix"] = "";
      formData["Action"] = "DELETE";
      formData["Task_Name"] = "Request_CIC_" + selectedTab?.description?.DSBP_PMP_PIMaterialID;
    }
    let payLoadData = [formData];
    let otherCicDatas = [];
    if(isToggleClick){
      otherCicDatas = dmTabValuesData[tabPanelList]?.groupCIC?.filter(item=>item?.DSBP_PMP_PIMaterialNumber !== formData?.DSBP_PMP_PIMaterialNumber);
      otherCicDatas = otherCicDatas?.map(item => {
        let filteredItem = {
            AWMProjectID: ProjectID,
            AWM_CICMatrix: item?.AWM_CIC_Matrix,
            AWM_CICNeeded: item?.AWM_CIC_Needed,
            AWM_GABrief: item?.AWM_GA_Brief,
            AWM_OtherReference: item?.AWM_Other_Reference,
            AWM_SupportingPMPDesign: item?.AWM_Supporting_PMP_Design,
            AWM_SupportingPMPLayout: item?.AWM_Supporting_PMP_Layout,
            Action: "",
            CICMatrix: cicMatrix,
            DSBP_InitiativeID: item?.DSBP_InitiativeID,
            DSBP_PMP_PIMaterialID: item?.DSBP_PMP_PIMaterialID,
            DSBP_PMP_PIMaterialNumber: item?.DSBP_PMP_PIMaterialNumber,
            updated: true,
            Task_Name:"Request_CIC_" + item?.DSBP_PMP_PIMaterialID
        };

        return filteredItem;
    });
      payLoadData = [formData,...otherCicDatas];
    }
    const updatedPmpDetails = { DSBPValues: payLoadData };

    // Call POST API of create new GA Brief
    if (formData?.AWM_GABrief === "New") {
      handleNewGaBrief();
    }

    // Call POST API to save tab data
    await onSubmitDependencyMappingAction(updatedPmpDetails, ProjectID);

    // Call GET API of dependency mapping
    // const {
    //   dependencyTableData,
    //   isRDTData,
    //   isIQData,
    //   isCDPTData,
    //   isGABrifData,
    // } = await getDependencyMappingDetails(selectedProject?.Project_ID);

    // const tableData = fetchData(
    //   dependencyTableData,
    //   isRDTData,
    //   isIQData,
    //   isCDPTData,
    //   isGABrifData
    // );

    // let updatedNewTabData = tableData?.filter(
    //   (data) =>
    //     data.DSBP_PMP_PIMaterialID ===
    //     selectedTab.description.DSBP_PMP_PIMaterialID
    // );

    // updateMappingTabValuesData(updatedNewTabData);
    // setFormData({});
    updateDMPMPTabData();
    setLoader(false);
  };

  const updateDMPMPTabData = async () => {
    const { dependencyTableData, isRDTData, isIQData, isCDPTData, isGABrifData } =
      await getDependencyMappingDetails(ProjectID);
    
    const tableData = fetchData(dependencyTableData, isRDTData, isIQData, isCDPTData, isGABrifData);

    let updatedNewTabData = tableData?.filter(
      (data) => data.DSBP_PMP_PIMaterialID === selectedTab.description.DSBP_PMP_PIMaterialID
    );

    updateMappingTabValuesData(updatedNewTabData);
  };

  const fetchData = (dependencyTableData, isRDTData, isIQData, isCDPTData, isGABrifData) => {
    if (dependencyTableData && dependencyTableData.length) {
      const transformedData = dependencyTableData.map((item) => {
        let transformedItem = {
          DSBP_InitiativeID: item.DSBP_InitiativeID,
          DSBP_PMP_PIMaterialDescription: item.DSBP_PMP_PIMaterialDescription,
          DSBP_PMP_PIMaterialID: item.DSBP_PMP_PIMaterialID,
          DSBP_PMP_PIMaterialNumber: item.DSBP_PMP_PIMaterialNumber,
        };

        transformedItem.Regional_Design_Template =
          item?.Preselected_AWM_RDT_Page?.map((item) => item.AWM_Design_Job_Name) || [];

        transformedItem.Color_Development_and_Print_Trial =
          item?.Preselected_AWM_CDPT_Page?.map((item) => item.AWM_Design_Job_Name) || [];

        transformedItem.Ink_Qualification =
          item?.Preselected_AWM_IQ_Page?.map((item) => item.AWM_Design_Job_Name) || [];

        transformedItem.AWM_GA_Brief = item?.Preselected_GABrief_Page?.[0]?.AWM_GABrief || " ";

        transformedItem = {
          ...transformedItem,
          AWM_CIC_Needed: item?.AWM_CIC_Page?.[0]?.AWM_CIC_Needed || "",
          AWM_Supporting_PMP_Layout: item?.AWM_CIC_Page?.[0]?.AWM_Supporting_PMP_Layout || "",
          AWM_Supporting_PMP_Design: item?.AWM_CIC_Page?.[0]?.AWM_Supporting_PMP_Design || "",
          AWM_Other_Reference: item?.AWM_CIC_Page?.[0]?.AWM_Other_Reference || "",
          AWM_CIC_Matrix: item?.AWM_CIC_Page?.[0]?.AWM_CIC_Matrix || "",
          // AWM_GA_Brief: item?.Preselected_DSBP_GA_Brief || [],
          AWM_CIC_Matrix_Requested: item?.AWM_CIC_Page?.[0]?.AWM_CIC_Matrix_Requested || "",
          AWM_CIC_Matrix_Toggle:item?.AWM_CIC_Page?.[0]?.AWM_CIC_Matrix_Toggle || "",
        };

        transformedItem = {
          ...transformedItem,
          DSBP_PICountry_Countries: item.DSBP_PICountry_Countries,
          DSBP_PILanguage_Languages: item.DSBP_PILanguage_Languages,
          DSM_PMP_PMPPackagingComponentType: item.DSM_PMP_PMPPackagingComponentType,
          DSM_PMP_PackagingSize: item.DSM_PMP_PackagingSize,
          DSM_PMP_PrinterPrimary: item.DSM_PMP_PrinterPrimary,
          DSM_PMP_PrinterSecondary: item.DSM_PMP_PrinterSecondary,
          DSM_PMP_PrintingProcess: item.DSM_PMP_PrintingProcess,
          DSM_PMP_SubstrateColor: item.DSM_PMP_SubstrateColor,
          DSM_PMP_TD_TDStatus: item.DSM_PMP_TD_TDStatus,
          DSM_PMP_TD_TDValue: item.DSM_PMP_TD_TDValue,
          DSM_PO_PMP_poMaterialNumber: item.DSM_PO_PMP_poMaterialNumber,
          ...item?.FPCStagingPage?.[0],
        };
        return transformedItem;
      });
      let columnNames = Object.keys(transformedData[0]);
      return transformedData;
    }
  };

  const isChangedValue = (value) => {
    const hasMatch = Boolean(
      changedData.find((el) => {
        return el.FieldName == value;
      })
    );
    return hasMatch;
  };

  const getLatestValue = (value) => {
    const data = changedData.filter((el) => {
      return el.FieldName === value;
    });
    return data[0]?.FieldValue;
  };

  const renderData = (tabData, group) => {
    let dependencyColumnNames1 = dmTabData.DMColumnNames;

    const dependencyColumnNames2 = dmTabData?.CDPTPageData?.length
      ? dependencyColumnNames1
      : dependencyColumnNames1.filter((item) => item.field !== "Color_Development_and_Print_Trial");

    const dependencyColumnNames3 = dmTabData?.RDTData?.length
      ? dependencyColumnNames2
      : dependencyColumnNames2.filter((item) => item.field !== "Regional_Design_Template");

    const dependencyColumnNames4 = dmTabData?.IQData?.length
      ? dependencyColumnNames3
      : dependencyColumnNames3.filter((item) => item.field !== "Ink_Qualification");

    let allColumns = dependencyColumnNames4;

    if (CICs === false) {
      allColumns = dependencyColumnNames4.filter(
        (item) =>
          item.field !== "AWM_CIC_Needed" &&
          item.field !== "AWM_GA_Brief" &&
          item.field !== "AWM_Supporting_PMP_Layout" &&
          item.field !== "AWM_Supporting_PMP_Design" &&
          item.field !== "AWM_Other_Reference" &&
          item.field !== "AWM_CIC_Matrix" &&
          item.field !== "AWM_CIC_Matrix_Requested"
      );
    }

    const groupOneCols = allColumns.filter((col) => col.group === 1);
    const groupTwoCols = allColumns.filter((col) => col.group === 2);
    const groupThreeCols = allColumns.filter((col) => col.group === 3);
    const convertedInObject = [tabData];

    const groupColumns =
      group === "groupOne"
        ? groupOneCols
        : group === "groupTwo"
        ? groupTwoCols
        : group === "groupThree"
        ? groupThreeCols
        : allColumns;
    if (groupColumns && groupColumns.length) {
      return groupColumns.map((field, index) => {
        const value = field?.field;
        // const filteredItems = convertedInObject?.filter(
        //   (item) => item && item[value] !== undefined
        // );
        let field1 = field?.field;

        if (value === "AWM_Supporting_PMP_Design" || value === "AWM_Other_Reference") {
          field1 = value + "_" + "(optional)";
        }

        let splittedCol = field1.split("_").join(" ");

        let selectedData = selectedTab?.description;
        let isCICMatrix = selectedData?.AWM_CIC_Matrix !== "" ? true : false;

        let isalreadyRequested =
          selectedData?.AWM_CIC_Matrix_Requested === "Yes" ||
          selectedData?.AWM_CIC_Requested === "Yes";

          let disableCICdropdown= selectedData?.AWM_CIC_Matrix_Requested === "Yes" ||  selectedData?.AWM_CIC_Requested === "Yes"||  selectedData?.AWM_CIC_Matrix_Toggle;

        // change this condition for development to check fullkit is ready or not

        let isCICRequestFullKitSatisfied =
          selectedData?.FullKit_Condition_Checking_Page?.[0]?.CIC_FullKit_Satisfied === "Yes"||
          selectedData?.FullKit_Condition_Checking_Page?.[1]?.CIC_FullKit_Satisfied === "Yes"
            ? true
            : false;
        // change this condition for development to check fullkit is ready or not
        let isCICMatrixRequestFullKitSatisfied =
          selectedData?.FullKit_Condition_Checking_Page?.[1]?.CICMatrix_FullKit_Satisfied === "Yes"||
          selectedData?.FullKit_Condition_Checking_Page?.[0]?.CICMatrix_FullKit_Satisfied === "Yes"
            ? true
            : false;

      let xmlRequestStatus =
      selectedData?.Copy_XML_Request_Status?.toLowerCase() === dsbpConstant?.xml_awaiting_response;
        let resp = getRequestText(
          cicNeeded,
          isCICMatrixRequestFullKitSatisfied,
          isCICRequestFullKitSatisfied,
          isCICMatrix,
          isalreadyRequested,
          xmlRequestStatus
        );

        let buttonLabel = resp.name;

        return convertedInObject.map((item) => {
          const showRedIcon = checkIfRedIcon(item);
          const showGreyIcon = checkIfGreyIcon(item);
          const showAlertIcon = showRedIcon || showGreyIcon;
          let isalreadyRequested =
          item.AWM_CIC_Matrix_Requested === 'Yes' || item.AWM_CIC_Requested === 'Yes';

          return (
            <tr key={item[value]}>
              <td className="columnWidth">
                {splittedCol?.replaceAll("_", " ")}
                {(field.field === "AWM_Supporting_PMP_Layout" ||
                  field.field === "AWM_Supporting_PMP_Design" ||
                  field.field === "AWM_Other_Reference") && (
                  <span>
                    <img src={CICInfo} className="cicinfoicontab" alt="" />
                    <Tooltip
                      position="top"
                      target=".cicinfoicontab"
                      className="tooltip-white-cic"
                      content={dsbpConstant?.cicTooltipMessage}
                    />
                  </span>
                )}
              </td>
              <td>
                {field.field === "Color_Development_and_Print_Trial" && (
                  <div className="d-flex justify-content-between">
                    <div style={{ maxWidth: 200 }}>
                      {item["Color_Development_and_Print_Trial"] &&
                      item["Color_Development_and_Print_Trial"].length >= 1
                        ? item["Color_Development_and_Print_Trial"].map((ele) => {
                            return <div className="awm-job-seperator">{ele}</div>;
                          })
                        : "N/A"}
                    </div>
                    <Tooltip target=".mddm-tab-edit-icon" position="left" />
                    <img
                      data-pr-tooltip="Manage Dependency"
                      src={editName}
                      alt="edit logo"
                      onClick={() => setShowManageDependency(true)}
                      className="mddm-tab-edit-icon"
                      disabled={!finalPermission}
                    />
                  </div>
                )}
                {field.field === "Regional_Design_Template" && (
                  <div className="d-flex justify-content-between">
                    <div style={{ maxWidth: 200 }}>
                      {item["Regional_Design_Template"] &&
                      item["Regional_Design_Template"].length >= 1
                        ? item["Regional_Design_Template"].map((ele) => {
                            return <div className="awm-job-seperator">{ele}</div>;
                          })
                        : "N/A"}
                    </div>
                    <Tooltip target=".mddm-tab-edit-icon" position="left" />
                    <img
                      data-pr-tooltip="Manage Dependency"
                      src={editName}
                      alt="edit logo"
                      onClick={() => setShowManageDependency(true)}
                      className="mddm-tab-edit-icon"
                      disabled={!finalPermission}
                    />
                  </div>
                )}
                {field.field === "Ink_Qualification" && (
                  <div className="d-flex justify-content-between">
                    <div style={{ maxWidth: 200 }}>
                      {item["Ink_Qualification"] && item["Ink_Qualification"].length >= 1
                        ? item["Ink_Qualification"].map((ele, index) => {
                            return <div className="awm-job-seperator">{ele}</div>;
                          })
                        : "N/A"}
                    </div>
                    <Tooltip target=".mddm-tab-edit-icon" position="left" />
                    <img
                      data-pr-tooltip="Manage Dependency"
                      src={editName}
                      alt="edit logo"
                      onClick={() => setShowManageDependency(true)}
                      className="mddm-tab-edit-icon"
                      disabled={!finalPermission}
                    />
                  </div>
                )}
                {field.field === "AWM_CIC_Needed" && (
                  <Form.Group controlId="groupName.ControlInput1" disabled={!isUserAccess}>
                    <Form.Select
                      placeholder="Select"
                      value={cicNeeded}
                      onChange={handleCICNeededChange}
                      style={{ width: "80%", fontSize: 12 }}
                      disabled={disableCICdropdown || item.AWM_CIC_Matrix_Requested === "Yes"}
                    >
                      <option className="hidden-option" value="" default selected>Select</option>
                      {dmTabData.cicNeededOptionList?.map((data) => (
                        <option key={data.code} value={data.name}>
                          {data.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}
                {field.field === "AWM_Supporting_PMP_Design" &&
                  (cicNeeded === "" || !cicNeeded || cicNeeded === "Yes" || cicNeeded === "N/A" ? (
                    " "
                  ) : (
                    <Form.Group
                      controlId="groupName.ControlInput1"
                      disabled={!isUserAccess}
                      // style={{ textAlign: "-webkit-center" }}
                    >
                      <Form.Select
                        placeholder="Select"
                        value={pmpDesign}
                        onChange={handlePmpDesignChange}
                        className={
                          pmpDesign === "" && pmpLayout === "" && otherRef === ""
                          ? "border-red" 
                          : "border-normal"
                        }
                        style={{ width: "80%", fontSize: 12 }}
                      >
                        <option value="">Select</option>
                        {dmTabData?.SPMPDesignData?.map((data,index) => (
                          <option key={data.code} data-dropdownMI={dropdownMI[index]}
                          data-dropdownInitiateID={dropdownInitiateID[index]} value={data}>
                            {data}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  ))}
                {field.field === "AWM_Supporting_PMP_Layout" &&
                  (cicNeeded === "" || !cicNeeded || cicNeeded === "Yes" || cicNeeded === "N/A" ? (
                    " "
                  ) : (
                    <Form.Group controlId="groupName.ControlInput1" disabled={!isUserAccess}>
                      <Form.Select
                        placeholder="Select"
                        value={pmpLayout}
                        onChange={handlePmpLayoutChange}
                        // className={
                        //   formData?.AWM_CICNeeded === 'No' &&
                        //   (formData?.AWM_SupportingPMPLayout === ' ' ||
                        //     formData?.AWM_SupportingPMPLayout === '')
                        //     ? 'border-color'
                        //     : ''
                        // }
                        className={
                          pmpDesign === "" && pmpLayout === ""  && otherRef === ""
                          ? "border-red" 
                          : "border-normal"
                        }
                        style={{ width: "80%", fontSize: 12 }}
                      >
                        <option value="">Select</option>
                        {dmTabData?.SPMPLayoutData?.map((data,index) => (
                          <option key={data.code} value={data}  data-dropdownMI={dropdownMI[index]}
                          data-dropdownInitiateID={dropdownInitiateID[index]}>
                            {data}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  ))}
                {field.field === "AWM_Other_Reference" &&
                  (cicNeeded === "" || !cicNeeded || cicNeeded === "Yes" ? (
                    " "
                  ) : (
                    <Form.Group disabled={!isUserAccess} controlId="groupName.ControlInput1">
                      <Form.Control
                        type="text"
                        maxLength={8}
                        value={otherRef}
                        onChange={handleOtherRefChange}
                        className={
                          cicNeeded === "No" && pmpDesign === "" && pmpLayout === "" && otherRef === ""
                          ? "border-red" 
                          : "border-normal"
                        }
                        style={{ width: "24%", fontSize: 12, height: "50%" }}
                      ></Form.Control>
                      {otherRef?.length < 8 &&
                        otherRef?.length !== 0 && (
                          <div className="text-red">{errorMessage}</div>
                      )}
                    </Form.Group>
                  ))}
                {field.field === "AWM_GA_Brief" && (
                  <div>
                    <Form.Group
                      controlId="groupName.ControlInput1"
                      disabled={!isUserAccess}
                      // style={{ textAlign: "-webkit-center" }}
                    >
                      <Form.Select
                        placeholder="Select"
                        value={gaBrief}
                        onChange={handleGABriefChange}
                        style={{ width: "80%", fontSize: 12 }}
                        className={
                          formData?.AWM_CICNeeded === "Yes" &&
                          (formData.AWM_GABrief === " " || formData.AWM_GABrief === "")
                            ? "border-color"
                            : ""
                        }
                        disabled={item.AWM_CIC_Matrix_Requested === "Yes"}
                      >
                      <option className="hidden-option" value="" default selected>
                        Select
                      </option>   
                        {dmTabData.GABriefData?.map((data) => (
                          <option key={data.File_Name} value={data.File_Name}>
                            {data.File_Name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                )}

                {field.field === "AWM_CIC_Matrix_Requested" &&
                  cicNeeded === "Yes" &&
                  deleteFlag !== true &&
                  (cicNeeded === "" ? (
                    ""
                  ) : (
                    <div className="cic-button-alignment">
                      <Button
                        style={{ fontSize: 12, height: 23, lineHeight: 13 }}
                        severity={resp?.severity}
                        disabled={resp?.disabled || !isUserAccess}
                        onClick={() => saveCICAndCicMatrixRequest(item, resp?.reqId)}
                        className={resp?.type === "text" ? "p-button-text" : ""}
                      >
                        {buttonLabel}
                      </Button>
                      {/* <Button style={{height:25, fontSize:12}} severity="success">Request CIC Matrix</Button>
            <Button  style={{height:25, fontSize:12}} severity="secondary" disabled>Request CIC </Button> */}
                    </div>
                  ))}

                {field.field === "AWM_CIC_Matrix" &&
                  item.AWM_CIC_Matrix !== "" &&
                  (cicNeeded === "" || !cicNeeded || cicNeeded === "No" || cicNeeded === "N/A" ? (
                    ""
                  ) : (
                    <div>
                      <span>
                        <img
                          src={deleteIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!isalreadyRequested) {
                              setDeleteFlag(true);
                            }
                          }}
                          alt="filter logo"
                          disabled={
                            isalreadyRequested || !isUserAccess
                              ? true
                              : false
                          }
                          style={{ height: 16}}
                          className="cursor-pointer"
                        />
                      </span>
                      <span> {selectedData?.AWM_CIC_Matrix} </span>
                      <span
                        className={
                          item[value] === true ? "cic-matrix-text-on" : "cic-matrix-text-off"
                        }
                      >
                        CIC Matrix Only
                      </span>
                      <img
                        src={
                          cicMatrix === "CIC MATRIX ONLY" || cicMatrix === "" ? toggleOff : toggleOn
                        }
                        className="add-new-design-intent-icon"
                        style={{ height: 20 }}
                        alt="Add role button"
                        disabled={
                          selectedData?.AWM_CIC_Matrix_Requested === "Yes" || !isUserAccess
                            ? true
                            : false
                        }
                        onClick={(e) => {
                          if (selectedData?.AWM_CIC_Matrix_Requested !== "Yes") {
                            let fieldValue = "CIC MATRIX ONLY";
                            if (cicMatrix === "CIC MATRIX ONLY") {
                              fieldValue = "CIC MATRIX AND CIC";
                            }

                            if (cicMatrix === "CIC MATRIX AND CIC") {
                              fieldValue = "CIC MATRIX ONLY";
                            }
                            setCICMatrix(fieldValue);
                            setToggleClick(true);
                            setFormData({
                              ...formData,
                              AWM_CICMatrix: fieldValue,
                              updated: true,
                            });
                          }
                        }}
                      />
                      <span
                        className={
                          item[value] === false ? "cic-matrix-text-on" : "cic-matrix-text-off"
                        }
                      >
                        CIC Matrix & CIC's
                      </span>
                    </div>
                  ))}
                {/* {field.field === "AWM_CIC_Matrix_Toggle" && <>{item["AWM_CIC_Matrix_Toggle"]}</>} */}

                {field.field === "DSBP_PMP_PIMaterialNumber" && (
                  <>
                    <span>
                      {isChangedValue("DSBP_PMP_PIMaterialNumber") ? (
                        <>
                          <span className="text-option text-red">
                            {getLatestValue("DSBP_PMP_PIMaterialNumber")}{" "}
                          </span>
                          <AlertIcon
                            iconType="error"
                            showAlertIcon={showAlertIcon}
                            onClick={() => {
                              navigate(
                                `/${currentUrl?.[1]}/DSBP/tab/dependencyMapping/ChangeNotification/${ProjectID}`,
                                {
                                  state: {
                                    from: "dependencyTabPage",
                                    assignee: assignee,
                                    InitiativeID: item.DSBP_InitiativeID,
                                    PIMaterialID: item.DSBP_PMP_PIMaterialID,
                                    PIMaterialNumber: item.DSBP_PMP_PIMaterialNumber,
                                  },
                                }
                              );
                            }}
                            editable={showGreyIcon}
                            tooltipClass="tooltip-white"
                            tooltipText={
                              showGreyIcon
                                ? dsbpConstant.xml_awaiting_response_enovia
                                : dsbpConstant.xml_view_changes
                            }
                            tooltipPosition={"top"}
                          />
                        </>
                      ) : (
                        <>
                          <span className="text-option">{item[value]} </span>
                          <AlertIcon
                            iconType="error"
                            showAlertIcon={showAlertIcon}
                            onClick={() => {
                              navigate(
                                `/${currentUrl?.[1]}/DSBP/tab/dependencyMapping/ChangeNotification/${ProjectID}`,
                                {
                                  state: {
                                    from: "dependencyTabPage",
                                    assignee: assignee,
                                    InitiativeID: item.DSBP_InitiativeID,
                                    PIMaterialID: item.DSBP_PMP_PIMaterialID,
                                    PIMaterialNumber: item.DSBP_PMP_PIMaterialNumber,
                                  },
                                }
                              );
                            }}
                            editable={showGreyIcon}
                            tooltipClass="tooltip-white"
                            tooltipText={
                              showGreyIcon
                                ? dsbpConstant.xml_awaiting_response_enovia
                                : dsbpConstant.xml_view_changes
                            }
                            tooltipPosition={"top"}
                          />
                        </>
                      )}
                    </span>
                  </>
                )}

                {field.field !== "Color_Development_and_Print_Trial" &&
                  field.field !== "Regional_Design_Template" &&
                  field.field !== "Ink_Qualification" &&
                  field.field !== "AWM_CIC_Needed" &&
                  field.field !== "AWM_Supporting_PMP_Design" &&
                  field.field !== "AWM_Supporting_PMP_Layout" &&
                  field.field !== "AWM_Other_Reference" &&
                  field.field !== "AWM_GA_Brief" &&
                  field.field !== "AWM_CIC_Matrix_Requested" &&
                  field.field !== "AWM_CIC_Matrix" &&
                  field.field !== "DSBP_PMP_PIMaterialNumber" && (
                    <>
                      <span>
                        {isChangedValue(field.field) ? (
                          <span className="text-red">{getLatestValue(field.field)}</span>
                        ) : (
                          <span>{item[value]}</span>
                        )}
                      </span>
                    </>
                  )}
              </td>
            </tr>
          );
        });
      });
    }
    return null;
  };

  const tabsCompo = (obj) => {
    const showGreyIcon = checkIfGreyIcon(obj?.description);
    const showRedIcon = checkIfRedIcon(obj?.description);
    const showAlertIcon = showRedIcon || showGreyIcon;
    return (
      <div className="tabsCompo">
        <div className="btn-right-wrapper">
          {showAlertIcon ? (
            <button
              className={`btn btn-right`}
              onClick={() => {
                navigate(
                  `/${currentUrl?.[1]}/DSBP/tab/dependencyMapping/ChangeNotification/${ProjectID}`,
                  {
                    state: {
                      from: "dependencyTabPage",
                      assignee: assignee,
                      InitiativeID: obj?.description?.DSBP_InitiativeID,
                      PIMaterialID: obj?.description?.DSBP_PMP_PIMaterialID,
                      PIMaterialNumber: obj?.description?.DSBP_PMP_PIMaterialNumber,
                    },
                  }
                );
              }}
            >
              {dsbpConstant.xml_view_changes_btn}
            </button>
          ) : null}
        </div>
        <div className="tabsCompoMainDM">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="pmp-details">PMP Details</Accordion.Header>
              <Accordion.Body className="pmp-details">
                <table className="table table-sm table-hover">
                  <tbody>{renderData(obj?.description, "groupOne")}</tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="mapping">Mapping</Accordion.Header>
              <Accordion.Body className="mapping">
                <table className="table table-sm table-hover">
                  <tbody>{renderData(obj?.description, "groupTwo")}</tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="dsm-fields">DSM Fields</Accordion.Header>
              <Accordion.Body className="dsm-fields">
                <table className="table table-sm table-hover">
                  <tbody>{renderData(obj?.description, "groupThree")}</tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    );
  };

  const handleDelete = (index) => {
    const unsavedChanges=JSON.parse(localStorage.getItem('unsavedChanges'));
    if (unsavedChanges) {
      setShowDialog(true);
      setPendingNavigation(index);
    }
    else{
      let updatedDataList=[];
      if(Array.isArray(storesTabList) && storesTabList.length > 0){
        updatedDataList = [...storesTabList];
      }
      updatedDataList.splice(index, 1);
      setStoresTabDataList(updatedDataList);
      if (tabPanelList >= storesTabList.length) {
        setTabPanelList(storesTabList.length - 1); // Select the last tab if the active tab is deleted
      }
    }
  };

  const CustomTabHeader = ({ tabHeader, index }) => {
    return (
      <div className="custom-tab-header">
        <span className="p-tabview-title">{tabHeader}</span>
        {index !== 0 && (
          <button className="close-button" onClick={() => handleDelete(index)}>
            &#x2715;
          </button>
        )}
      </div>
    );
  };
  const onTabChange = (index) => {
    setTabPanelList(index);
    if (index === 0) {
      return navigateToDSBP();
    }
  };

  const renderTabs = () => {
    return filteredDataList.map((obj, index) => (
      <TabPanel
        key={index}
        header={<CustomHeader tabHeaderDetails={obj} index={index} handleDelete={handleDelete} />}
        scrollable
      >
        <>
          {loader ? (
            <LoadingOverlay active={true} spinner text="" />
          ) : (
            index !== 0 && tabsCompo(obj)
          )}
        </>
      </TabPanel>
    ));
  };

  return (
    <div className="dependency-mapping-tab-view">
      {dmTabValuesData?.length > 1 && tabPanelList !== 0 ? (
        <TabView
          activeIndex={tabPanelList}
          scrollable={dmTabValuesData?.length > 3 ? true : false}
          onTabChange={(e) => onTabChange(e.index)}
        >
          {renderTabs()}
        </TabView>
      ) : (
        navigateToDSBP()
      )}

      <FooterButtons
        handleCancel={handleCancel}
        hideSaveButton={true}
        onSubmit={onSubmit}
        formValid={isSubmitEnabled}
        checkReadWriteAccess={!false}
        submitAndSave="Save"
        Assignee={assignee}
      />
      <ManageDependency
        showManageDependency={showManageDependency}
        setShowManageDependency={setShowManageDependency}
        ProjectID={ProjectID}
        getApiParentPage={updateDMPMPTabData}
        finalPermission={finalPermission}
      />
        {deleteFlag && (
        <DsbpCommonPopup
          actionHeader={dsbpConstant?.deleteCicMatrixMessage}
          dasbpDialog={deleteFlag}
          setDasbpDialog={setDeleteFlag}
          yesButtonShow={false}
          cancelButtonShow={true}
          submitButtonShow={true}
          onSubmit={onSubmit}
        ></DsbpCommonPopup>
      )}
     <UnsavedChangesDialog visible={showDialog} onConfirm={confirmNavigation} onCancel={cancelNavigation} />
    </div>
  );
};

export default DMPMPSpecificTabView;