import React, { useState, useRef, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { Form } from "react-bootstrap";
import { SelectSupplier } from "../constant";
import { useSelector } from "react-redux";
import { Checkbox } from "primereact/checkbox";

import Download from "./icons/download.svg";
import Export from "./icons/Export.svg";
import Import from "./icons/Import.svg";
import EnabledDownload from "./icons/EnabledDownload.svg";
import CalendarIcon from "../../common/CalendarIcon/CalendarIcon";

import { FileUpload } from "primereact/fileupload";
import { AcpService } from "../../../service/ACPService";
import {
  jsonToXLConverter,
  xlToJSONConverter,
  getStartAndEndOfMonth
} from "../../../service/DashboardService";
import {
  fetchExternalCapacityData,
  fetchInternalCapacityData,
  importUpdateExternalCapacityData,
  importUpdateInternalCapacityData,
} from "../../../apis/acpApi";

import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";

function MangeCapacityHeader({
  setCapacityTableType,
  capacityTableType,
  supplierValues,
  setEnableEdit,
  enableEdit,
  setSelectedMonth,
  setTableEdited,
  setSupplierSelected,
  userGroup,
  setLoader,
}) {
  const [edit, setEdit] = useState(false);
  const { DropDownValuesData } = useSelector((state) => state.DropDownValuesReducer);
  const suppliers = DropDownValuesData?.ArtWorkProjectSetupPage?.Artwork_Supplier;
  const capTableTypes = ["External Capacity", "Internal Capacity"];
  const [supplierValue, setSupplierValue] = useState("");
  const [value, setValue] = useState(new Date());
  const actions = ["Import", "Export", "Download"];
  const [uploadedFile, setUploadedFile] = useState("");
  const [enableReportDownload, setEnableReportDownload] = useState(false);
  const [generatedReportData, setGeneratedReportData] = useState([]);
  const [parsedFileObj, setParsedFileObj] = useState({});
  const [supplierDropdown, setSupplierDropdown] = useState([]);
  const toast = useRef(null);

  const actionClicked = (type) => {
    switch (type) {
      case "Import":
        break;
      case "Export":
        exportCapacityData();
        break;
      case "Download":
        generateCapacityReport();
        break;

      default:
        break;
    }
  };

  const fileInputRef = useRef();
  const handleSelect = (event) => {
    setUploadedFile(event?.files[0]);
    fileInputRef.current.clear();
  };

  const handleError = (event) => {
    console.error("Upload error:", event);
  };
  const openUploader = () => {
    const inputElement = document.querySelector('input[type="file"]');
    if (inputElement) {
      inputElement.click();
    } else {
      console.error("File input not found");
    }
  };

  useEffect(() => {
    xlToJSONConverter(uploadedFile, setParsedFileObj);
  }, [uploadedFile]);

  useEffect(() => {
    if (!AcpService.isUBN(parsedFileObj) && Object.keys(parsedFileObj).length > 0) {
      if (capacityTableType === "External Capacity") {
        setLoader(true);
        const result = parsedFileObj?.results?.map((item) => {
          return {
            BVE: !AcpService.isUBN(item?.BVE) ? item?.BVE : "",
            SerialNumber: !AcpService.isUBN(item?.SerialNumber) ? item?.SerialNumber : "",
            Capacity: !AcpService.isUBN(item?.Capacity) ? item?.Capacity : "",
            TaskType: !AcpService.isUBN(item?.TaskType) ? item?.TaskType : "",
            Date: !AcpService.isUBN(item?.Date)
              ? AcpService.changeDateFromISTtoUTC(new Date(item?.Date))
              : "",
          };
        });

        let payload = {
          ManageExternalCapacity: {
            UserGroup: userGroup,
            UpdatedExternalCapacity: result,
          },
        };

        importUpdateExternalCapacityData(payload).then((data) => {
          setLoader(false);
          let reportData = data?.ManageExternalCapacity?.UpdatedExternalCapacity;
          if (reportData?.length > 0) {
            setGeneratedReportData(reportData);
            setEnableReportDownload(true);
            showReportToast("Report Generated");
          } else {
            showReportToast("Report Not Generated", "error");
          }
        });
      } else {
        setLoader(true);
        const result = parsedFileObj?.results?.map((item) => {
          return {
            CalendarWeek: !AcpService.isUBN(item?.CalendarWeek) ? item?.CalendarWeek : "",
            Capacity: !AcpService.isUBN(item?.Capacity) ? item?.Capacity : "",
            Task: !AcpService.isUBN(item?.Task) ? item?.Task : "",
            MonthYear: !AcpService.isUBN(item?.MonthYear) ? item?.MonthYear : "",
          };
        });

        let payload = {
          ManageInternalCapacity: {
            UserGroup: userGroup,
          },
          UpdatedInternalCapacities: result,
        };

        importUpdateInternalCapacityData(payload).then((data) => {
          setLoader(false);
          let reportData = data?.UpdatedInternalCapacities;
          if (reportData?.length > 0) {
            setGeneratedReportData(reportData);
            setEnableReportDownload(true);
            showReportToast("Report Generated");
          } else {
            showReportToast("Report Not Generated", "error");
          }
        });
      }
    }
  }, [parsedFileObj, userGroup, capacityTableType]);

  useEffect(() => {
    if (!AcpService.isUBN(suppliers)) {
      const sortedData = AcpService.sortDataAlphaNumeric(suppliers, "Supplier_Name");
      setSupplierDropdown(sortedData);
    }
  }, [suppliers]);

  const showReportToast = (message, type) => {
    if (toast.current) {
      toast.current.show({
        severity: !AcpService.isUBN(type) ? type : "success",
        summary: message,
        detail: !AcpService.isUBN(type) ? "Error!" : "Successful!",
        life: 3000,
      });
    }
  };

  const generateCapacityReport = () => {
    if (!enableReportDownload) return;
    if (generatedReportData.length > 0) {
      setEnableReportDownload(false);
      jsonToXLConverter(generatedReportData, capacityTableType, capacityTableType);
    }
  };
  const exportCapacityData = () => {
    setLoader(true);
    if (capacityTableType === "External Capacity") {
      const dates = getStartAndEndOfMonth(value);
      const payload = {
        ManageExternalCapacity: {
          Start_Date: dates.startDate,
          End_Date: "",
          Supplier: "",
          UserGroup: userGroup,
        },
      };
      fetchExternalCapacityData(payload).then((data) => {
        setLoader(false);
        let _data = data?.ManageCapacityExt;
        if (_data?.length) {
          showReportToast("Data Exported");
          jsonToXLConverter(_data, capacityTableType + "_FullReport", capacityTableType);
        } else showReportToast("No Data Available to Export", "error");
      });
    } else {
      let yearMonth = AcpService.formatDateToYearMonth(value);
      let cwList = AcpService.getWeekRangesForMonth(value, () => {});
      let payload = {
        ManageInternalCapacity: {
          MonthYear: yearMonth,
          CWList: cwList,
          UserGroup: userGroup,
        },
      };

      fetchInternalCapacityData(payload).then((data) => {
        setLoader(false);
        let _data = data?.InternalCapacityList;
        if (_data?.length) {
          showReportToast("Data Exported");
          jsonToXLConverter(_data, capacityTableType + "_FullReport", capacityTableType);
        } else
          showReportToast(
            "No Data Available to Export for " + AcpService.formatYearMonthToLabel(yearMonth),
            "error"
          );
      });
    }
  };

  return (
    <div className="main-cap-header">
      <div className="mr">
        <Form.Select
          value={capacityTableType}
          className="select-supplier"
          placeholder="Select Supplier"
          onChange={(e) => {
            setCapacityTableType(e.target.value);
            setEdit(false);
            setEnableEdit(true);
            setTableEdited(false);
            setSupplierValue("");
            setSupplierSelected("");
            setGeneratedReportData([]);
            setEnableReportDownload(false);
            setUploadedFile("");
            setParsedFileObj({});
          }}
        >
          {capTableTypes?.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </Form.Select>
      </div>
      {capacityTableType === "External Capacity" && (
        <div className="supplier drop mr">
          <Form.Select
            value={supplierValue}
            className="select-supplier"
            placeholder="Select Supplier"
            onChange={(e) => {
              setSupplierValue(e.target.value);
              setSupplierSelected(e.target.value);
              setTableEdited(false);
              setEnableEdit(true);
              setEdit(false);
            }}
          >
            <option value="">{SelectSupplier}</option>
            {supplierDropdown?.map((bve, index) => (
              <option key={index} value={bve?.Supplier_Name}>
                {bve?.Supplier_Name}
              </option>
            ))}
          </Form.Select>
        </div>
      )}

      <div
        className={`${
          capacityTableType === "Internal Capacity"
            ? `acp-calendar manage-cap-calender internal mr`
            : "acp-calendar manage-cap-calender mr"
        }`}
      >
        <Calendar
          dateFormat="M-yy"
          showIcon={true}
          value={value}
          onChange={(e) => {
            setSelectedMonth(e.value);
            setValue(e.value);
            setEnableEdit(true);
            setEdit(false);
            setTableEdited(false);
          }}
          onClick={() => {}}
          view="month"
          className={true ? "calendar-icon-white" : "calendar-icon"}
          icon={<CalendarIcon />}
        />
      </div>

      <div
        className={`${
          capacityTableType === "Internal Capacity" ? `action-buttons internal` : "action-buttons"
        }`}
      >
        <div>
          {actions?.map((el, inx) => {
            return (
              <div key={inx} className={el}>
                {el === "Import" ? (
                  <>
                    <img
                      data-pr-tooltip={el}
                      className="action-icon"
                      onClick={() => openUploader()}
                      alt={el}
                      src={Import}
                    ></img>
                    <FileUpload
                      mode="basic"
                      name="demo[]"
                      url="/api/upload"
                      accept=".csv,.xls,.xlsx"
                      maxFileSize={1000000}
                      onSelect={handleSelect}
                      onError={handleError}
                      style={{ display: "none" }}
                      ref={fileInputRef} // Assign the ref to the FileUpload component
                    />
                  </>
                ) : (
                  <>
                    <Toast ref={toast} />
                    <Tooltip target=".action-icon" />
                    <img
                      data-pr-tooltip={el}
                      className="action-icon"
                      onClick={() => actionClicked(el)}
                      src={
                        el === "Export" ? Export : enableReportDownload ? EnabledDownload : Download
                      }
                      alt={el}
                      style={{ cursor: el === "Export" ? "pointer" : "default" }}
                    />
                  </>
                )}
              </div>
            );
          })}
          <div className="edit-table">
            <label>
              <Checkbox
                inputId="editTable"
                name="Edit"
                value="Edit"
                onChange={(e) => {
                  setEdit(e.checked);
                  setEnableEdit(!enableEdit);
                  // setTableEdited(true);
                }}
                checked={edit}
              />
              <label htmlFor="editTable" className="ml-2">
                Edit
              </label>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MangeCapacityHeader;
